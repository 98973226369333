// React libs
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// Layout
import AppLayout from '../../../App/Components/Layout/AppLayout/AppLayout';
// Components
import MapHeader from '../Components/Layout/Header/MapHeader';
import MapSidebar from '../Components/Layout/Sidebar/MapSidebar';
import MapCanvas from '../Components/Canvas/MapCanvas';
import ExternalTerritoriesMenu from '../Components/ExternalTerritoriesMenu/ExternalTerritoriesMenu';
// Contexts
import ActiveLayerContext from '../Data/Contexts/ActiveLayerContext';
import PhaseTypeContext from '../Data/Contexts/PhaseTypeContext';
import LinkTypeContext from '../Data/Contexts/LinkTypesContext';
import PoisContext from '../Data/Contexts/PoisContext';
import PoiTypeContext from '../Data/Contexts/PoiTypesContext';
import PoisLinksContext from '../Data/Contexts/PoisLinksContext';
import MapConfigContext from '../Data/Contexts/MapConfigContext';
import MapHiddenLegendContext from '../Data/Contexts/MapHiddenLegendContext';
import MapFiltersContext from '../Data/Contexts/MapFiltersContext';
// Services
import MapService from '../Data/Services/MapService';
// Type
import * as Types from './MapScene.type';
import * as MapTypes from '../Data/Models/Map.type';
import * as CoreTypes from '../../../Core/Data/Models/Core.type';
// Common
import CoreCommon from '../../../Core/Resources/Common';
// Utils
import { formatMapFilters } from '../Utils/Map'

const MapScene: FC<Types.IProps> = () => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'map']);

  // Refs
  const mapRef = useRef();

  // State
  const [pois, updatePois]: [
    MapTypes.IPoi[],
    (pois: MapTypes.IPoi[]) => void
  ] = useState<MapTypes.IPoi[]>([]);
  const [arePoisLoading, setPoisLoading]: [boolean, Function] = useState<
    boolean
  >(false);
  const [poisLinks, updatePoisLinks]: [
    MapTypes.IPoiLink[],
    (pois: MapTypes.IPoiLink[]) => void
  ] = useState<MapTypes.IPoiLink[]>([]);
  const [mapConfig, updateMapConfig]: [
    MapTypes.IMapConfig | undefined,
    (config: MapTypes.IMapConfig | undefined) => void
  ] = useState<MapTypes.IMapConfig | undefined>(undefined);
  const [isMapConfigLoading, setMapConfigLoading]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const [phaseTypes, updatePhaseTypes]: [
    MapTypes.IPhaseType[],
    (types: MapTypes.IPhaseType[]) => void
  ] = useState<MapTypes.IPhaseType[]>([]);
  const [arePhaseTypesLoading, setPhaseTypesLoading]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const [poiTypes, updatePoiTypes]: [
    MapTypes.IPoiType[],
    (pois: MapTypes.IPoiType[]) => void
  ] = useState<MapTypes.IPoiType[]>([]);
  const [arePoiTypesLoading, setPoiTypesLoading]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const [linkTypes, updateLinkTypes]: [
    MapTypes.ILinkType[],
    (links: MapTypes.ILinkType[]) => void
  ] = useState<MapTypes.ILinkType[]>([]);
  const [areLinkTypesLoading, setLinkTypesLoading]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const [mapFilters, updateMapFilters]: [
    MapTypes.IMapFilters,
    (filters: MapTypes.IMapFilters) => void
  ] = useState<MapTypes.IMapFilters>({
    initialized: false,
    links: false,
    markers: { links: {}, projects: {}, resources: {} },
    needMapRefresh: true,
    advanced: {}
  });
  const [hiddenLegendMapId, updateHiddenLegendMapId]: [
    string | undefined,
    (id: string | undefined) => void
  ] = useState<string | undefined>();
  const [areAdvancedFiltersSelected, updateAreAdvancedFiltersSelected] = useState<boolean>(false)
  const [areFavoriteFiltersSelected, updateAreFavoriteFiltersSelected] = useState<boolean>(false)
  const [isSidebarOpened, setSideBarOpened]: [boolean, Function] = useState(
    false
  );
  const [sidebarContentKey, setSidebarContentKey]: [
    string,
    Function
  ] = useState('');
  const [title, setTitle]: [string, Function] = useState('');
  const [currentLayer, setCurrentLayer]: [
    MapTypes.ILayer | undefined,
    Function
  ] = useState(undefined);
  const [otherTerritory, setOtherTerritory]: [
    MapTypes.IExternalTerritory | undefined,
    Function
  ] = useState(undefined);
  const [currentCenter, setCurrentCenter]: [
    [number, number] | undefined,
    Function
  ] = useState<[number, number] | undefined>(undefined);
  const [currentZoom, setCurrentZoom]: [
    number | undefined,
    Function
  ] = useState<number | undefined>(undefined);
  const [isCreatingPoiOnMap, setCreatingPoiOnMap]: [
    string | undefined,
    Function
  ] = useState<string | undefined>('');

  // Effects
  useEffect(() => {
    if (currentLayer) {
      setTitle(currentLayer!.name);
    } else if (mapConfig && mapConfig.layerMap) {
      setTitle(mapConfig.layerMap.name);
    }
  }, [currentLayer, mapConfig, setTitle]);
  useEffect(() => {
    if (!currentLayer) {
      setCurrentLayer(mapConfig?.layerMap);
    }
  }, [currentLayer, mapConfig]);
  useEffect(() => {
    if (mapFilters.needMapRefresh && !arePoisLoading) {
      setPoisLoading(true);
      const filters = formatMapFilters(mapFilters);
      MapService.getPois(filters)
        .then((data: MapTypes.IPoiData) => {
          updatePois(data.data.markers);
          updatePoisLinks(data.data.links);
          const newMapFilters = { ...mapFilters };
          newMapFilters.needMapRefresh = false;
          updateMapFilters(newMapFilters);
          setPoisLoading(false);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setPoisLoading(false);
        });
    } else if (pois.length === 0) {
      onPoiCreation(undefined);
    }
  }, [
    arePoisLoading,
    enqueueSnackbar,
    mapFilters,
    pois,
    setPoisLoading,
    t,
  ]);
  useEffect(() => {
    if (!mapConfig && !isMapConfigLoading) {
      setMapConfigLoading(true);
      MapService.getMapConfig()
        .then((data: MapTypes.IMapConfigData) => {
          updateMapConfig(data.data);
          setMapConfigLoading(false);
        })
        .catch((e: CoreTypes.IWsException) => {
          enqueueSnackbar(
            e?.error?.message || t('common:errors.defaultMessage'),
            {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'error',
            }
          );
          setMapConfigLoading(false);
        });
    }
  }, [enqueueSnackbar, isMapConfigLoading, mapConfig, setMapConfigLoading, t]);
  useEffect(() => {
    setPhaseTypesLoading(true);
    MapService.getPhaseTypes()
      .then((data: MapTypes.IPhaseTypeData) => {
        updatePhaseTypes(data.data);
        setPhaseTypesLoading(false);
      })
      .catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
        setPhaseTypesLoading(false);
      });
  }, [
    enqueueSnackbar,
    t,
  ]);
  useEffect(() => {
    setPoiTypesLoading(true);
    MapService.getPoiTypes()
      .then((data: MapTypes.IPoiTypesData) => {
        updatePoiTypes(data.data);
        setPoiTypesLoading(false);
      })
      .catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
        setPoiTypesLoading(false);
      });
  }, [enqueueSnackbar, t]);
  useEffect(() => {
    setLinkTypesLoading(true);
    MapService.getLinkTypes()
      .then((data: MapTypes.ILinkTypesData) => {
        updateLinkTypes(data.data);
        setLinkTypesLoading(false);
      })
      .catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
        setLinkTypesLoading(false);
      });
  }, [enqueueSnackbar, t]);

  // Getters
  const isLoading = () => {
    return (
      process.env.NODE_ENV !== 'test' &&
      (isMapConfigLoading || arePoisLoading || arePhaseTypesLoading || arePoiTypesLoading || areLinkTypesLoading)
    );
  };
  const getLoadingMessages = () => {
    const messages = [];
    if (arePhaseTypesLoading) {
      messages.push(t('common:loading.phaseTypes'));
    }
    if (isMapConfigLoading) {
      messages.push(t('map:loading.config'));
    }
    if (arePoisLoading) {
      messages.push(t('map:loading.pois'));
    }
    if (arePoiTypesLoading) {
      messages.push(t('map:loading.poiTypes'));
    }
    if (areLinkTypesLoading) {
      messages.push(t('map:loading.linkTypes'));
    }
    return messages;
  };

  // Handlers
  const toggleMapSideBar = (isOpened: boolean, content: string) => {
    setSideBarOpened(isOpened);
    setSidebarContentKey(isOpened ? content : '');
  };
  const onLayerClick = (layer: MapTypes.ILayer) => {
    setCurrentLayer(layer);
  };
  const onTerritoryClick = (t: MapTypes.IExternalTerritory) => {
    setCurrentZoom(t.zoom);
    setCurrentCenter([t.coords.lat, t.coords.lng]);
    setOtherTerritory(t);
  };
  const resetOtherTerritory = () => {
    setCurrentZoom(undefined);
    setCurrentCenter(undefined);
    setOtherTerritory(undefined);
  };
  const onExport = (output: 'pdf' | 'png') => {
    (mapRef?.current as any)?.export(output);
  };
  const onPoiCreation = (type: string | undefined) => {
    setCreatingPoiOnMap(type);
  };

  return (
    <PoiTypeContext.Provider value={{ poiTypes, updatePoiTypes }}>
      <PhaseTypeContext.Provider value={{ phaseTypes, updatePhaseTypes }}>
        <LinkTypeContext.Provider value={{ linkTypes, updateLinkTypes }}>
          <PoisContext.Provider value={{ pois, updatePois }}>
            <PoisLinksContext.Provider value={{ poisLinks, updatePoisLinks }}>
              <MapConfigContext.Provider value={{ mapConfig, updateMapConfig }}>
                <MapFiltersContext.Provider
                  value={{ areAdvancedFiltersSelected, areFavoriteFiltersSelected, mapFilters, updateAreAdvancedFiltersSelected, updateAreFavoriteFiltersSelected, updateMapFilters }}
                >
                  <MapHiddenLegendContext.Provider value={{ hiddenLegendMapId, updateHiddenLegendMapId }}>
                    <ActiveLayerContext.Provider value={{ activeLayer: currentLayer, setActiveLayer: setCurrentLayer }}>
                      <AppLayout
                        headerConf={{
                          title: {
                            label: t('map:title'),
                            icon: 'map-o',
                            subLabel: title,
                          },
                        }}
                        isLoading={isLoading()}
                        loadingMessages={getLoadingMessages()}
                      >
                        <div
                          data-testid='map-page'
                          className='flex flex-col h-full w-full'
                        >
                          <MapHeader
                            contentKey={sidebarContentKey}
                            toggleMapSideBar={toggleMapSideBar}
                            isSidebarOpened={isSidebarOpened}
                            onExport={onExport}
                            onPoiCreation={onPoiCreation}
                            updateCenter={(center: [number, number]) =>
                              setCurrentCenter(center)
                            }
                            updateZoom={(zoom: number) => setCurrentZoom(zoom)}
                          />
                          <div className='flex flex-1 overflow-hidden relative w-full'>
                            {isSidebarOpened ? (
                              <MapSidebar
                                toggleMapSideBar={toggleMapSideBar}
                                contentKey={sidebarContentKey}
                                onLayerClick={onLayerClick}
                                activeLayer={currentLayer}
                              />
                            ) : null}
                            <MapCanvas
                              ref={mapRef}
                              layer={currentLayer}
                              onHomeClick={resetOtherTerritory}
                              title={title}
                              isCreatingPoiOnMap={isCreatingPoiOnMap}
                              currentCenter={currentCenter}
                              currentZoom={currentZoom}
                            />
                          </div>
                          {mapConfig && (
                            <ExternalTerritoriesMenu
                              onTerritoryClick={onTerritoryClick}
                              defaultTerritory={otherTerritory}
                            />
                          )}
                        </div>
                      </AppLayout>
                    </ActiveLayerContext.Provider>
                  </MapHiddenLegendContext.Provider>
                </MapFiltersContext.Provider>
              </MapConfigContext.Provider>
            </PoisLinksContext.Provider>
          </PoisContext.Provider>
        </LinkTypeContext.Provider>
      </PhaseTypeContext.Provider>
    </PoiTypeContext.Provider>
  );
};

export default MapScene;
