// React libs
import React, { FC, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import Input from '@material-ui/core/Input';
// Components
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '../../../../../Core/Components/UiKit/Button/Button';
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import MapHeaderPanel from './MapHeaderPanel/MapHeaderPanel';
import Separator from '../../../../../Core/Components/UiKit/Separator/Separator';
import Typography from '../../../../../Core/Components/UiKit/Typography/Typography';
import { SideBarWidthByComponent } from '../Sidebar/MapSidebar'
// Contexts
import PoisContext, { IPoisContext } from '../../../Data/Contexts/PoisContext';
import MapFiltersContext, {
  IMapFiltersContext,
} from '../../../Data/Contexts/MapFiltersContext';
import UserContext, {
  IUserContext,
} from '../../../../../Core/Data/Contexts/UserContext';
// Hooks
import usePois from '../../../Data/Hooks/Pois';
// Services
import MapService from '../../../Data/Services/MapService';
// Type
import * as Types from './MapHeader.type';
import * as MapTypes from '../../../Data/Models/Map.type';
// Misc
import { SidebarContentKeys } from '../Sidebar/MapSidebar';
import { HeaderPanelContentKeys } from './MapHeaderPanel/MapHeaderPanel';
// Utils
import { areFiltersSelected, creationMarkerId } from '../../../Utils/Map';
import { isAdmin, isWriter } from '../../../../../Core/Utils/User';
// Common
import CoreCommon from '../../../../../Core/Resources/Common';
// SVGs
import PoiProjectMarker from '../../../Resources/assets/svg/PoiProjectMarker';
import OtherPoiMarker from '../../../Resources/assets/svg/OtherPoiMarker';

const MapHeader: FC<Types.IProps> = ({
  contentKey,
  isSidebarOpened,
  onExport,
  onPoiCreation,
  toggleMapSideBar,
  updateCenter,
  updateZoom,
}) => {
  // Variables
  const { t } = useTranslation(['common', 'map']);
  const { enqueueSnackbar } = useSnackbar();
  const exportActions: any[] = [
    {
      icon: <FaIcon name='file-pdf-o' className='fill-current' />,
      name: t('map:header.export.actions.pdf'),
      key: 'pdf',
    },
    {
      icon: <FaIcon name='file-image-o' className='fill-current' />,
      name: t('map:header.export.actions.png'),
      key: 'png',
    },
  ];
  const creationActions: any[] = [
    {
      icon: (
        <div className='marker-icon'>
          <OtherPoiMarker className='h-6 w-6' />
        </div>
      ),
      name: t('map:header.creation.actions.resource'),
      key: 'project',
    },
    {
      icon: (
        <div className='marker-icon'>
          <PoiProjectMarker className='h-6 w-6' />
        </div>
      ),
      name: t('map:header.creation.actions.project'),
      key: 'resource',
    },
  ];
  const controller = new AbortController();
  const signal = controller.signal;

  // Hooks
  const allPois = usePois();

  // Contexts
  const { pois, updatePois }: IPoisContext = useContext(PoisContext);
  const { mapFilters }: IMapFiltersContext = useContext(MapFiltersContext);
  const { user }: IUserContext = useContext(UserContext);

  // State
  const [isCardMenuActive, setCardMenuActive]: [boolean, Function] = useState<
    boolean
  >(isSidebarOpened);
  const [isFilteMenuActive, setFilteMenuActive]: [boolean, Function] = useState<
    boolean
  >(isSidebarOpened);
  const [isExportOpen, setExportOpen]: [boolean, Function] = useState<boolean>(
    false
  );
  const [isCreationOpen, setCreationOpen]: [boolean, Function] = useState<
    boolean
  >(false);
  const [headerPanelContent, setHeaderPanelContent]: [
    string | undefined,
    Function
  ] = useState<string | undefined>(undefined);
  const [searchValue, setSearchValue]: [string, Function] = useState<string>(
    ''
  );
  const [isSearchOpen, setSearchOpen]: [boolean, Function] = useState<boolean>(
    false
  );
  const [searchOptions, setSearchOptions]: [any[], Function] = useState<any[]>(
    []
  );
  const [isSearchLoading, setSearchLoading]: [boolean, Function] = useState<
    boolean
  >(false);

  // Effects
  useEffect(() => {
    if (!isSidebarOpened) {
      setCardMenuActive(false);
      setFilteMenuActive(false);
    }
  }, [isSidebarOpened]);

  // Handlers
  const cardMenuHandler = () => {
    const newValue = !isCardMenuActive;
    setCardMenuActive(newValue);
    setFilteMenuActive(false);
    toggleMapSideBar(newValue, SidebarContentKeys.card);
  };
  const filterMenuHandler = () => {
    const newValue = !isFilteMenuActive;
    setFilteMenuActive(newValue);
    setCardMenuActive(false);
    toggleMapSideBar(newValue, SidebarContentKeys.filters);
  };
  const headerPanelHandler = (panel: string | undefined) => {
    const newValue = headerPanelContent === panel ? undefined : panel;
    setHeaderPanelContent(newValue);
    if (!newValue) {
      const newPois: MapTypes.IPoi[] = pois.filter(
        (p: MapTypes.IPoi) => creationMarkerId !== p.id
      );

      updatePois(newPois);
    }
    onPoiCreation(newValue);
  };
  const exportHandler = (output: 'png' | 'pdf') => {
    setExportOpen(false);
    onExport(output);
  };
  const creationHandler = (output: 'project' | 'resource') => {
    const handlers = {
      project: () => headerPanelHandler(HeaderPanelContentKeys.resource),
      resource: () => headerPanelHandler(HeaderPanelContentKeys.project),
    };
    handlers[output]();
  };
  const searchPoi = async (e: any) => {
    const search = e.target.value;
    setSearchValue(search);
    if (search.length >= 3) {
      setSearchLoading(true);
      try {
        const results: MapTypes.IPoiSearchData = await MapService.searchPoi(
          search,
          20,
          signal
        );
        const options = results.data.reduce(
          (list: any[], item: MapTypes.IPoi) => {
            list.push({ ...item });
            return list;
          },
          []
        );
        setSearchOptions(options);
        setSearchOpen(true);
        setSearchLoading(false);
      } catch (e) {
        setSearchLoading(false);
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
      }
    }
  };
  const handleSearchPoi = (event: any, value: MapTypes.IPoi | null) => {
    if (value) {
      updateCenter(value.geo.coordinates);
      updateZoom(13);
      enqueueSnackbar(t('map:header.search.warning'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'warning',
      });
    }
  };

  // Renders
  const renderMenuButton = (
    icon: string,
    label: string,
    handler: (...args: any) => void,
    isActive: boolean,
    iconClasses: string = ''
  ) => (
    <Button
      variant={isActive ? 'outlined' : 'text'}
      color='secondary'
      className={`flex items-center mx-2 ${isActive ? 'text-active border-active hover:bg-active-hover' : 'hover:bg-active-hover'
        }`}
      onClick={handler}
      size='small'
    >
      <FaIcon name={icon} className={`text-lg mr-1 ${iconClasses}`} />
      <div className='relative'>{label}</div>
    </Button>
  );
  const renderSpeedDialButton = (
    ariaLabel: string,
    label: any,
    isOpened: boolean,
    onToggle: Function,
    actions: {
      icon: any;
      name: string;
      key: string;
    }[],
    onElementClick: (key: any) => void
  ) => (
    <SpeedDial
      ariaLabel={ariaLabel}
      className='hidden md:flex'
      classes={{
        root: 'self-start',
        fab:
          'bg-main-light rounded shadow-none text-gray-700 hover:bg-active-hover hover:text-selection-inverse',
      }}
      icon={<div>{label}</div>}
      onClose={() => onToggle(false)}
      onOpen={() => onToggle(true)}
      open={isOpened}
      direction='down'
      FabProps={{ variant: 'extended', size: 'small' }}
    >
      {actions.map(action => (
        <SpeedDialAction
          key={action.key}
          icon={action.icon}
          classes={{
            fab: 'hover:bg-active-hover hover:text-selection-inverse',
          }}
          tooltipTitle={action.name}
          onClick={() => onElementClick(action.key)}
        />
      ))}
    </SpeedDial>
  );

  return (
    <div data-testid='map-header'>
      <div className='bg-main-light flex h-12 items-center justify-between p-0 sm:p-2 text-gray-700 w-full'>
        <div className='flex items-center'>
          {renderMenuButton(
            'globe',
            t('map:header.backgroundCard'),
            cardMenuHandler,
            isCardMenuActive
          )}
          {renderMenuButton(
            'filter',
            t('map:header.filter'),
            filterMenuHandler,
            isFilteMenuActive,
            areFiltersSelected(mapFilters)
              ? 'bg-active h-6 p-1 rounded-full text-selection-inverse w-6'
              : ''
          )}
        </div>
        <div className='flex self-start'>
          <div className='flex h-12 items-center sm:h-8'>
            {user &&
              (isAdmin(user) || isWriter(user)) &&
              renderSpeedDialButton(
                t('map:header.creation.title'),
                <div className='flex items-center'>
                  <FaIcon name='plus' className='mr-1' />
                  <div>{t('map:header.creation.title')}</div>
                </div>,
                isCreationOpen,
                setCreationOpen,
                creationActions,
                creationHandler
              )}
            {renderSpeedDialButton(
              t('map:header.export.title'),
              <div className='flex items-center'>
                <FaIcon name='files-o' className='mr-1' />
                <div>{t('map:header.export.title')}</div>
              </div>,
              isExportOpen,
              setExportOpen,
              exportActions,
              exportHandler
            )}
            <div className='flex h-full items-center'>
              <Separator type='vertical' className='h-full mx-2' />
              <div className='w-64'>
                <Autocomplete
                  open={isSearchOpen}
                  onOpen={() => {
                    setSearchOpen(true);
                  }}
                  onClose={() => {
                    setSearchOpen(false);
                  }}
                  getOptionSelected={(
                    option: MapTypes.IPoi,
                    value: MapTypes.IPoi
                  ) => option.id === value.id}
                  getOptionLabel={(option: MapTypes.IPoi) => option.name}
                  options={searchOptions}
                  loading={isSearchLoading}
                  noOptionsText={t('common:forms.autocomplete.noOptions')}
                  onChange={handleSearchPoi}
                  renderInput={(params: any) => (
                    <div ref={params.InputProps.ref}>
                      <Input
                        {...params.inputProps}
                        placeholder={t('map:header.search.label')}
                        value={searchValue}
                        onChange={searchPoi}
                        fullWidth
                        endAdornment={
                          <React.Fragment>
                            {isSearchLoading ? (
                              <FaIcon name='spinner' className='fa-spin mr-6' />
                            ) : null}
                          </React.Fragment>
                        }
                      />
                    </div>
                  )}
                />
              </div>
              <Separator type='vertical' className='h-full mx-2' />

              <Typography variant='subtitle2' className='flex items-center'>
                <i className='fa fa-map-marker mr-1' aria-hidden='true'></i>
                <div>
                  {t('map:header.availablePois', {
                    nb: allPois.data ? allPois.data.count : '...',
                  })}
                </div>
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className={`relative ${isSidebarOpened ? 'md:ml-' + SideBarWidthByComponent[contentKey] : ''}`}>
        {typeof headerPanelContent !== 'undefined' && (
          <MapHeaderPanel
            contentKey={headerPanelContent}
            onCreationDone={() => setHeaderPanelContent(undefined)}
          />
        )}
      </div>
    </div>
  );
};

MapHeader.propTypes = {
  contentKey: PropTypes.string.isRequired,
  isSidebarOpened: PropTypes.bool.isRequired,
  toggleMapSideBar: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
};

export default MapHeader;
