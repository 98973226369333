// React libs
import React, { FC, useContext, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Rnd } from 'react-rnd'
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from '@material-ui/core';
// Components
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import Typography from '../../../../../Core/Components/UiKit/Typography/Typography';
// Context
import MapHiddenLegendContext, { IMapHiddenLegendContext } from '../../../Data/Contexts/MapHiddenLegendContext';
// Type
import * as Types from './MapLegend.type';
import * as MapTypes from '../../../Data/Models/Map.type';

const LegendDetails = ({ image, type }: { image: MapTypes.ILayerMapImage | undefined, type: string }) => image ? (
  <Accordion className='m-0' key={image.image.id}>
    <AccordionSummary
      expandIcon={<FaIcon name='chevron-down' className='text-lg' />}
      aria-controls='panel1a-content'
      id='panel1a-header'
      className='min-h-0 truncate'
      classes={{ root: 'border-none', content: 'm-0' }}
    >
      <Typography variant='h6' className='p-2 truncate'>
        {image.legend}
      </Typography>
    </AccordionSummary>
    <AccordionDetails
      classes={{
        root: 'flex flex-col',
      }}
    >
      <div className='flex flex-col my-2'>
        <img
          src={`/api/images/${image.image.id}/media`}
          alt={type}
        />
      </div>
    </AccordionDetails>
  </Accordion>
) : null;

interface IPos {
  x: number
  y: number
}

interface ISize {
  width: number
  height: number | string
}


// react-rnd doesn't give a way to make the legend starting position fixe and not dependent on screen resolution
const getLegendPos = ({ legendSize }: { legendSize: ISize }): IPos => {
  const containerWidth = document.getElementById('map-canvas')?.offsetWidth ?? 0
  const deltaWidth = 50
  const deltaHeight = 11
  return { x: containerWidth - deltaWidth - legendSize.width, y: deltaHeight }
}

const RndScale = ({ children, isHidenWhenExport }: { children: JSX.Element, isHidenWhenExport: boolean }) => {

  // State
  const [legendSize, updateLegendSize] = useState<ISize>({ width: 300, height: 'auto' })
  const [legendPos, updateLegendPos] = useState<IPos>(getLegendPos({ legendSize }))

  // Effects

  // update legendPos on screen resize
  useEffect(() => {
    const listener = () => {
      updateLegendPos(getLegendPos({ legendSize }))
    }

    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [legendSize])

  // Handlers
  const onDragStop = useCallback((e, d) => updateLegendPos({ x: d.x, y: d.y }), [])
  const onResizeStop = useCallback((e, direction, ref, delta, position) => {
    updateLegendSize({
      width: ref.style.width,
      height: ref.style.height
    })
    updateLegendPos({
      x: position.x,
      y: position.y
    })
  }, [])

  return <Rnd
    size={legendSize}
    onDragStop={onDragStop}
    onResizeStop={onResizeStop}
    position={legendPos}
    enableResizing={{
      top: false,
      bottom: false,
      bottomLeft: true,
      bottomRight: true,
      left: true,
      right: true,
      topLeft: true,
      topRight: true,
    }}
    bounds='parent'
    className={`z-1500 ${isHidenWhenExport ? ' hidden' : ''} overflow-hidden`}
    id='mapLegendbox'
    data-testid='map-legend-box'
  >
    {children}
  </Rnd>
}

const MapLegend: FC<Types.IProps> = ({ isHidenWhenExport, layer, legendDataByType }) => {
  const { t } = useTranslation(['map']);

  // Contexts
  const { hiddenLegendMapId, updateHiddenLegendMapId }: IMapHiddenLegendContext = useContext(MapHiddenLegendContext);
  // Handlers
  const closeLegend = useCallback(() => layer !== undefined && updateHiddenLegendMapId(layer.id), [layer, updateHiddenLegendMapId])

  return hiddenLegendMapId === undefined || hiddenLegendMapId !== layer?.id ?
    <RndScale isHidenWhenExport={isHidenWhenExport}>
      <div className='bg-main-light rounded'>
        <Typography variant='h4' className='p-2 truncate'>
          {t('map:legendBox:title')}
          <Tooltip title={t('map:legendBox.actions.closeLegend') ?? ''}>
            <span>
              <FaIcon aria-label='close legend' className='text-xl float-right cursor-pointer' name='times' onClick={closeLegend} />
            </span>
          </Tooltip>
        </Typography>
        {['analysis', 'method', 'knowMore'].map((key: string) =>
          <LegendDetails image={legendDataByType[key]} type={key} key={key} />
        )}
      </div>
    </RndScale>
    : null
}

MapLegend.propTypes = {
  isHidenWhenExport: PropTypes.bool.isRequired,
  layer: PropTypes.any,
  legendDataByType: PropTypes.any.isRequired,
}

export default MapLegend