// React libs
import React, { FC, useState } from 'react';
import get from 'lodash/get'
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextFieldComponent from '@material-ui/core/TextField';
// Components
import FaIcon from '../../Icon/FaIcon/FaIcon';
import FieldError from '../../Error/FieldError/FieldError';
// Type
import * as Types from './TextField.type';
// Utils
import { hasErrors } from '../../../../Utils/FormUtils'

const TextField: FC<Types.IProps> = ({
  field,
  form,
  helper,
  icon,
  type,
  canDisplayPassword,
  transform,
  ...props
}) => {
  const { errors, touched } = form;

  const [passwordDisplayed, setPasswordDisplayed]: [
    boolean,
    Function
  ] = useState(false);

  let inputProps: any = { type };
  if (type === 'number') {
    inputProps = { inputMode: 'numeric' };
  }

  if (type === 'password' && canDisplayPassword) {
    inputProps = {
      endAdornment: (
        <InputAdornment position='end'>
          <IconButton
            aria-label='toggle password visibility'
            onClick={() => setPasswordDisplayed(!passwordDisplayed)}
            onMouseDown={(event: any) => event.preventDefault()}
          >
            {!passwordDisplayed ? (
              <FaIcon name='eye' />
            ) : (
              <FaIcon name='eye-slash' />
            )}
          </IconButton>
        </InputAdornment>
      ),
    };
  }

  const customField = {
    ...field,
    type: type === 'password' && passwordDisplayed ? 'text' : type,
    onClick: (e: any) => {
      e.stopPropagation();
      e.preventDefault();
    },
  };

  if (transform !== undefined) {
    customField.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      form.setFieldTouched(field.name, true)

      let value: any = e.target.value
      if (typeof transform === 'function') {
        value = transform(value)
      } else if (value !== '') {
        value = transform === 'integer' ? Math.round(+value) : +value
      }

      form.setFieldValue(field.name, value)
    }
  }

  if (icon) {
    const defaultIconProps = (
      <InputAdornment position={icon.position}>
        <FaIcon
          name={icon.component}
          onClick={(e: any) => {
            if (icon.onClick) icon.onClick(e);
          }}
          className={`mx-2 ${icon.onClick ? 'cursor-pointer' : ''}`}
        />
      </InputAdornment>
    );
    if (icon.position === 'start') {
      inputProps = {
        startAdornment: { defaultIconProps },
      };
    } else if (icon.position === 'end') {
      inputProps = {
        endAdornment: defaultIconProps,
      };
    }
  }

  return (
    <div className='w-full' data-testid='textfield'>
      <TextFieldComponent
        classes={{ root: 'w-full' }}
        error={
          errors &&
          touched &&
          touched[field.name] &&
          errors[field.name] !== undefined
        }
        helperText={helper}
        InputProps={inputProps}
        autoComplete='on'
        {...customField}
        {...props}
      />
      {hasErrors(form) && (
        <FieldError
          color={props.color}
          errors={get(errors, field.name)}
          touched={get(touched, field.name)}
        />
      )}
    </div>
  );
};

TextField.defaultProps = {
  canDisplayPassword: false,
};

export default TextField;
