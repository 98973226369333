// Types
import * as AdvancedFiltersTypes from '../Components/Form/AdvancedPoiFilters/AdvancedPoiFiltersForm.type'

interface IDefaultAdvancedFiltersNames {
  [name: string]: {
    label: string
    value: string
    getData?: (data: any, type: 'project' | 'resource') => any
    widget: {
      id: string
    }
  }
}

export const defaultAdvancedFiltersNames: IDefaultAdvancedFiltersNames = {
  fkpoiType: {
    label: 'map:sidebar.filters.advancedFilters.type',
    value: 'fkpoiType',
    getData: (data, type) => data[type].types,
    widget: {
      id: 'LIST'
    }
  },
  'thematics.thematic.id': {
    label: 'map:sidebar.filters.advancedFilters.thematic',
    value: 'thematics.thematic.id',
    getData: data => data.thematic,
    widget: {
      id: 'VALUESLIST'
    }
  },
  fkterritory: {
    label: 'map:sidebar.filters.advancedFilters.territory',
    value: 'fkterritory',
    getData: data => data.territories,
    widget: {
      id: 'VALUESLIST'
    }
  },
  keywords: {
    label: 'map:sidebar.filters.advancedFilters.keywords',
    value: 'keywords',
    widget: {
      id: 'FIELD'
    }
  }
}

interface IAdvancedFiltersOperators {
  [operator: string]: {
    label: string
    value: string
    format: (...args: any) => any
    parseTest: (...args: any) => boolean
    parse: (...args: any) => any
    widgets: string[]
  }
}

const defValuesNameKey = 'valueSlots.valueDef.id'
const defValuesValueKey = 'valueSlots.value'
const parseAdvancedFilter = (filter: any) => {
  // default filter
  if (!('$exists' in filter) && !('$notexists' in filter)) {
    const [name, value] = Object.entries(filter)[0]
    return {
      defaultFilter: true,
      name,
      value
    }
  }

  filter = filter.$exists ?? filter.$notexists
  return {
    defaultFilter: false,
    name: filter[0][defValuesNameKey] ?? filter[1][defValuesNameKey],
    value: filter[0][defValuesValueKey] ?? filter[1][defValuesValueKey]
  }
}

// Inspired from the patter Chain of Responsibility
export const advancedFiltersOperators: IAdvancedFiltersOperators = {
  like: {
    value: 'like',
    label: 'map:sidebar.filters.advancedFilters.like',
    widgets: ['FIELD'],
    format: (filter: AdvancedFiltersTypes.IFormValue, defaultProperty) => {
      const value = `/.*${filter.value}.*/`
      return defaultProperty || filter.name in defaultAdvancedFiltersNames
        ? {
          [filter.name]: value
        }
        : {
          $exists: [
            { [defValuesNameKey]: filter.name },
            { [defValuesValueKey]: value }
          ]
        }
    },
    parseTest: (filter: any) => {
      const { value } = parseAdvancedFilter(filter)
      const reg = /\/\.\*.+\.\*\//
      return reg.test(value)
    },
    parse: (filter: any) => {
      const { name, value } = parseAdvancedFilter(filter)
      const reg = /\/\.\*(.+)\.\*\//
      return {
        name,
        operator: 'like',
        value: reg.exec(value)?.[1]
      }
    }
  },
  equalTo: {
    value: 'equalTo',
    label: 'map:sidebar.filters.advancedFilters.equalTo',
    widgets: ['LIST', 'FIELD', 'NUMBER', 'FLOAT', 'RADIOS', 'BOOLEAN', 'DATE', 'THREECOLORSSCALES'],
    format: (filter: AdvancedFiltersTypes.IFormValue) =>
      filter.name in defaultAdvancedFiltersNames
        ? {
          [filter.name]: filter.value
        }
        : {
          $exists: [
            { [defValuesNameKey]: filter.name },
            { [defValuesValueKey]: filter.value }
          ]
        },
    parseTest: (filter: any) => {
      const { value, defaultFilter } = parseAdvancedFilter(filter)
      return ['string', 'number', 'boolean'].includes(typeof value)
        && !advancedFiltersOperators.like.parseTest(filter)
        && (defaultFilter || filter.$exists !== undefined)
    },
    parse: (filter: any) => {
      const { name, value } = parseAdvancedFilter(filter)
      return {
        name,
        operator: 'equalTo',
        value
      }
    }
  },
  differentTo: {
    value: 'differentTo',
    label: 'map:sidebar.filters.advancedFilters.differentTo',
    widgets: ['LIST', 'FIELD', 'NUMBER', 'FLOAT', 'RADIOS', 'BOOLEAN', 'DATE', 'THREECOLORSSCALES'],
    format: (filter: AdvancedFiltersTypes.IFormValue) =>
      filter.name in defaultAdvancedFiltersNames
        ? {
          [filter.name]: {
            $ne: filter.value
          }
        }
        : {
          $notexists: [
            { [defValuesNameKey]: filter.name },
            { [defValuesValueKey]: filter.value }
          ]
        },
    parseTest: (filter: any) => {
      const { value } = parseAdvancedFilter(filter)
      return value.$ne !== undefined
        || (filter.$notexists !== undefined && ['string', 'number', 'boolean'].includes(typeof value))
    },
    parse: (filter: any) => {
      const { name, value } = parseAdvancedFilter(filter)
      return ({
        name,
        operator: 'differentTo',
        value: value.$ne ?? value
      })
    }
  },
  greaterThan: {
    value: 'greaterThan',
    label: 'map:sidebar.filters.advancedFilters.greaterThan',
    widgets: ['NUMBER', 'FLOAT', 'DATE'],
    format: (filter: AdvancedFiltersTypes.IFormValue) => ({
      $exists: [
        { [defValuesNameKey]: filter.name },
        {
          [defValuesValueKey]: {
            $gt: filter.value
          }
        }
      ]
    }),
    parseTest: (filter: any) => {
      const { value } = parseAdvancedFilter(filter)
      return value.$gt !== undefined
    },
    parse: (filter: any) => {
      const { name, value } = parseAdvancedFilter(filter)
      return {
        name,
        operator: 'greaterThan',
        value: value.$gt
      }
    }
  },
  lessThan: {
    value: 'lessThan',
    label: 'map:sidebar.filters.advancedFilters.lessThan',
    widgets: ['NUMBER', 'FLOAT', 'DATE'],
    format: (filter: AdvancedFiltersTypes.IFormValue) => ({
      $exists: [
        { [defValuesNameKey]: filter.name },
        {
          [defValuesValueKey]: {
            $lt: filter.value
          }
        }
      ]
    }),
    parseTest: (filter: any) => {
      const { value } = parseAdvancedFilter(filter)
      return value.$lt !== undefined
    },
    parse: (filter: any) => {
      const { name, value } = parseAdvancedFilter(filter)
      return {
        name,
        operator: 'lessThan',
        value: value.$lt
      }
    }
  },
  greaterThanOrEqualTo: {
    value: 'greaterThanOrEqualTo',
    label: 'map:sidebar.filters.advancedFilters.greaterThanOrEqualTo',
    widgets: ['NUMBER', 'FLOAT', 'DATE'],
    format: (filter: AdvancedFiltersTypes.IFormValue) => ({
      $exists: [
        { [defValuesNameKey]: filter.name },
        {
          [defValuesValueKey]: {
            $gte: filter.value
          }
        }
      ]
    }),
    parseTest: (filter: any) => {
      const { value } = parseAdvancedFilter(filter)
      return value.$gte !== undefined
    },
    parse: (filter: any) => {
      const { name, value } = parseAdvancedFilter(filter)
      return {
        name,
        operator: 'greaterThanOrEqualTo',
        value: value.$gte
      }
    }
  },
  lessThanOrEqualTo: {
    value: 'lessThanOrEqualTo',
    label: 'map:sidebar.filters.advancedFilters.lessThanOrEqualTo',
    widgets: ['NUMBER', 'FLOAT', 'DATE'],
    format: (filter: AdvancedFiltersTypes.IFormValue) => ({
      $exists: [
        { [defValuesNameKey]: filter.name },
        {
          [defValuesValueKey]: {
            $lte: filter.value
          }
        }
      ]
    }),
    parseTest: (filter: any) => {
      const { value } = parseAdvancedFilter(filter)
      return value.$lte !== undefined
    },
    parse: (filter: any) => {
      const { name, value } = parseAdvancedFilter(filter)
      return {
        name,
        operator: 'lessThanOrEqualTo',
        value: value.$lte
      }
    }
  },
  contains: {
    value: 'contains',
    label: 'map:sidebar.filters.advancedFilters.contains',
    widgets: ['VALUESLIST', 'CHECKBOXES'],
    format: (filter: AdvancedFiltersTypes.IFormValue) => {
      const value = {
        $in: filter.value
      }
      return filter.name in defaultAdvancedFiltersNames
        ? {
          [filter.name]: value
        }
        : {
          $exists: [
            { [defValuesNameKey]: filter.name },
            { [defValuesValueKey]: value }
          ]
        }
    },
    parseTest: (filter: any) => {
      const { value, defaultFilter } = parseAdvancedFilter(filter)
      return value.$in !== undefined && (defaultFilter || filter.$exists !== undefined)
    },
    parse: (filter: any) => {
      const { name, value } = parseAdvancedFilter(filter)
      return ({
        name,
        operator: 'contains',
        value: value.$in
      })
    }
  },
  notContains: {
    value: 'notContains',
    label: 'map:sidebar.filters.advancedFilters.notContains',
    widgets: ['VALUESLIST', 'CHECKBOXES'],
    format: (filter: AdvancedFiltersTypes.IFormValue) =>
      filter.name in defaultAdvancedFiltersNames
        ? {
          [filter.name]: {
            $nin: filter.value
          }
        }
        : {
          $notexists: [
            { [defValuesNameKey]: filter.name },
            {
              [defValuesValueKey]: {
                $in: filter.value
              }
            }
          ]
        },
    parseTest: (filter: any) => {
      const { value } = parseAdvancedFilter(filter)
      return value.$nin !== undefined || (filter.$notexists !== undefined && value.$in !== undefined)
    },
    parse: (filter: any) => {
      const { name, value } = parseAdvancedFilter(filter)
      return ({
        name,
        operator: 'notContains',
        value: value.$nin ?? value.$in
      })
    }
  },
}

export const getFormatPoiFilter = () => {
  const advanced = {
    $or: [
      {
        $and: [
          { isProject: true }
        ]
      },
      {
        $and: [
          { isProject: false }
        ]
      }
    ]
  }
  return {
    formatPoiFilter(type: 'project' | 'resource', filter: AdvancedFiltersTypes.IFormValue) {
      const globalFilter: any[] = type === 'project' ? advanced.$or[0].$and : advanced.$or[1].$and
      globalFilter.push(advancedFiltersOperators[filter.operator].format(filter))
    },
    advanced
  }
}

export const parsePoiFilter = (filters: any) => {
  const project: any[] = []
  const resource: any[] = []

  filters.forEach(({ $and }: any) => {
    $and = [...$and]
    const isProjectIndex = $and.findIndex(({ isProject }: any) => isProject !== undefined)
    const [{ isProject }] = $and.splice(isProjectIndex, 1)
    const collection = isProject ? project : resource
    $and.forEach((filter: any) => {
      const operator = Object.values(advancedFiltersOperators).find((operator: any) => operator.parseTest(filter))
      if (operator !== undefined) {
        collection.push(operator.parse(filter))
      }
    })
  })

  return {
    project,
    resource
  }
}
