// React libs
import FormGroup from '@material-ui/core/FormGroup'
import get from 'lodash/get'
import React, { FC, useCallback } from 'react';
// Components
import Checkbox from '../Checkbox/Checkbox';
import FieldError from '../../Error/FieldError/FieldError';
// Type
import * as Types from './CheckboxGroup.type';
// Utils
import { hasErrors } from '../../../../Utils/FormUtils'


const CheckboxGroup: FC<Types.IProps> = ({
  field,
  form,
  options,
  ...props
}) => {
  const setFieldValue = useCallback((name: string, newValue: boolean) => {
    form.setFieldTouched(field.name, true)

    const list = Array.isArray(field.value) ? [...field.value] : []
    if (newValue) {
      list.push(name)
    } else {
      list.splice(list.indexOf(name), 1)
    }

    form.setFieldValue(field.name, list)
  }, [field.name, field.value, form])

  return <FormGroup data-testid='checkboxGroup'>
    {options.map(option => {
      const checked = field.value?.includes(option.value) ?? false
      return <Checkbox
        key={option.value}
        field={{ name: option.value, value: checked }}
        form={{
          setFieldValue
        }}
        label={option.label}
        labelControlled
        {...props}
      />
    })}
    {hasErrors(form) && (
      <FieldError
        color='secondary'
        errors={get(form.errors, field.name)}
        touched={get(form.touched, field.name)}
      />
    )}
  </FormGroup>
}

export default CheckboxGroup;
