export default class Routes {
  // Home
  static routeHome = '';

  /**
   * AUTH
   */
  // Login
  static routeLogin = 'connexion';
  // Forgot password
  static routeForgotPassword = 'mot-de-passe-oublie';
  // Reset password
  static routeResetPassword = 'nouveau-mot-de-passe';
  // Logout
  static routeLogout = 'deconnexion';

  /**
   * PROFILE
   */
  static routeProfile = 'mon-profil';
  static routeAddress = 'address'

  /**
   * APP
   */
  // Map
  static routeMap = 'carte';

  // Projects
  static routeProject = 'projet';
  static routeCreateProject = `creation`;
  static routePreviewProject = `détails`;
  static routeUpdateProject = `modification`;

  // Resources
  static routeResource = 'ressource';
  static routeCreateResource = `creation`;
  static routePreviewResource = `détails`;
  static routeUpdateResource = `modification`;

  /**
   * ADMIN
   */
  static routeAdmin = 'admin';
  static routeLayerManager = `${Routes.routeAdmin}/fonds-de-carte`;

  /**
   * MISC
   */
  // Error
  static routeError = 'erreur';
  // Tests
  static routeTest = 'test';
  static routeBryntumTest = `${Routes.routeTest}/bryntum`;
}
