// React libs
import React, { FC, useState, useLayoutEffect } from 'react';
// Type
import * as Types from './Gantt.type';

const Gantt: FC<Types.IProps> = ({
  children,
  containerId,
  dependenciesUrl,
  onTaskSelect,
  preset,
  taskUrl,
  title,
}) => {
  // Variables
  const config: any = {
    columns: null,
    eventBorderWidth: 0,
    taskStore: {
      type: 'gantt_taskstore',
      autoLoad: true,
      proxy: {
        type: 'ajax',
        url: taskUrl,
      },
    },
    dependencyStore: {
      type: 'gantt_dependencystore',
      autoLoad: true,
      proxy: {
        type: 'ajax',
        url: dependenciesUrl,
      },
    },
    title: title,
    width: '100%',
    height: '100%',
    // split: this.props.split,
    viewPreset: preset,
    // rowHeight: this.props.rowHeight,
    renderTo: containerId,
    listeners: {
      taskclick: (gantt: any, taskRecord: any) => {
        onTaskSelect && onTaskSelect(taskRecord);
      },
    },
  };

  // State
  // eslint-disable-next-line
  const [widget, setWidget]: [any, Function] = useState(null);

  useLayoutEffect(() => {
    const updateWidget = () => {
      setWidget(Ext?.create('Gnt.panel.Gantt', config));
    };
    updateWidget();

    // eslint-disable-next-line
  }, []);

  if (children) {
    config.columns = React.Children.map(children, child => {
      return Ext?.apply({}, (child as any).props);
    });
  }

  return (
    <div
      className='flex flex-col h-full items-center justify-center w-full'
      data-testid='gantt'
    >
      <div className='h-full w-full' id={containerId}>
        {children}
      </div>
    </div>
  );
};

export const Column: FC<any> = () => null;

export default Gantt;
