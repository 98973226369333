// React libs
import React, { FC, useState, Fragment } from 'react';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../../../../../Core/Components/UiKit/Button/Button';
import TextFieldMia from '../../../../../../Core/Components/UiKit/Form/TextField/TextField';
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// Type
import * as Types from './UserPofilForm.type';
// Utils
import {
  getFormPropTypes, isDisabled,
} from '../../../../../../Core/Utils/FormUtils';


const UserProfilForm: FC<Types.IProps> = ({
    defaultValues,
    miscData,
    miscFunctions,
    onFormSubmit,
    validationSchema,
  }) => (
    <Formik
      initialValues={defaultValues}
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps: FormikProps<any>) => (
        <UserProfilFormikForm
          {...formikProps}
          miscFunctions={miscFunctions}
          miscData={miscData}
        />
      )}
      
    </Formik>
  );
  
  UserProfilForm.propTypes = getFormPropTypes();
  
  export default UserProfilForm;


  const UserProfilFormikForm = ({
    errors,
    isSubmitting,
    touched,
    }: FormikProps<any> & any) => {
    const { t } = useTranslation(['common', 'user']);
    const fieldContainerClasses = 'flex w-full items-center';
    const fieldContainerFieldClasses = `${fieldContainerClasses} flex-1`;
    const [edit, setEdit] = useState<any>();
    
    const handleReset = ()=>{
      setTimeout(() => {
        setEdit(false)
        }, 100);
      
    }
    return (
      <>
      <div className="flex w-full">
        <div className="mt-3 mb-4 ml-4 items-center">
          <Typography variant="h5" gutterBottom>
          Identité  
          </Typography>
        </div>
          {!edit && 
        <div className="mt-2">
                    <Button 
          onClick={() => setEdit(true)} 
          variant={"outlined"}
          startIcon={
            <Fragment>
              {edit && <span>...</span> }
              <FaIcon name='pencil' />
            </Fragment>
                  } 
          className="mb-4 ml-4"
          disabled={edit ? true : false}
          >
            {edit ? t("user:En cours d'édition") : t('user:Éditer') }
          </Button>
        </div>
        }   
      </div>
      <Divider variant="middle"/>
      <Form data-testid='form-user-profils' className='flex w-full mt-2 ml-2'>
            <div className='flex flex-col w-11/12'>
              <div className={`${fieldContainerFieldClasses}`}>
                <Field
                  id='firstname'
                  name='firstname'
                  component={TextFieldMia}
                  label={t('user:Nom')}
                  color='secondary'
                  className='flex-1'
                  disabled={edit ? false : true}
                />
              </div>
              <div className={`${fieldContainerFieldClasses}`}>
                  <Field
                    id='name'
                    name='name'
                    label={t('user:Prénom')}
                    component={TextFieldMia}
                    color='secondary'
                    className='flex-1'
                    disabled={edit ? false : true}
                  />
              </div>
              <div className={`${fieldContainerFieldClasses}`}>
                <Field
                  id='url'
                  name='url'
                  component={TextFieldMia}
                  label={t('user:Url')}
                  color='secondary'
                  className='flex-1'
                  disabled={edit ? false : true}
                />
              </div>
              <div className={`${fieldContainerFieldClasses}`}>
                <Field
                  id='mail'
                  name='mail'
                  component={TextFieldMia}
                  label={t('user:Émail')}
                  color='secondary'
                  className='flex-1'
                  disabled={edit ? false : true}
                />
              </div>
              <div className={`${fieldContainerFieldClasses}`}>
                <Field
                  id='phone'
                  name='phone'
                  component={TextFieldMia}
                  label={t('user:Téléphone')}
                  color='secondary'
                  className='flex-1'
                  disabled={edit ? false : true}
                />
              </div>
              <div className={`${fieldContainerFieldClasses}`}>
                <Field
                  id='mobile'
                  name='mobile'
                  component={TextFieldMia}
                  label={t('user:Mobile')}
                  color='secondary'
                  className='flex-1'
                  disabled={edit ? false : true}
                />
              </div>
              <div className={`${fieldContainerFieldClasses}`}>
                <Field
                  id='comment'
                  name='comment'
                  component={TextFieldMia}
                  label={t('user:Commentaire')}
                  color='secondary'
                  className='flex-1'
                  multiline
                  disabled={edit ? false : true}
                />
              </div>
              {edit &&
              <div className={`${fieldContainerClasses} justify-center`} >
                <Button
                  type='reset'
                  variant='text'
                  size='large'
                  className='mx-2'
                  onClick={handleReset}
                >
                  {t("ANNULER")}
                </Button>
                <Button
                  type='submit'
                  variant='text'
                  size='large'
                  className='mx-2'
                  onClick={handleReset}
                  disabled={isDisabled(errors, isSubmitting, touched)}
                  startIcon={
                    isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : null
                  }
                >
                  {t("ENREGISTRER")}
                </Button>
                
              </div>
              }
            </div>
        </Form>
        </>
      );
    };
    