// React libs
import React, { FC, useCallback, useContext, useMemo } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
// Components
import AdvancedPoiFiltersForm from '../../../../../../../Core/Components/Form/AdvancedPoiFilters/AdvancedPoiFiltersForm'
// Context
import MapFiltersContext from '../../../../../Data/Contexts/MapFiltersContext';
// Types
import * as Types from './AdvancedFilters.type'
import * as MapTypes from '../../../../../Data/Models/Map.type'
// Utils
import { parsePoiFilter, getFormatPoiFilter } from '../../../../../../../Core/Utils/Filters';

const AdvancedFilters: FC<Types.IProps> = () => {
  // Context
  const { mapFilters, updateMapFilters } = useContext(MapFiltersContext);
  // Variables
  const { t } = useTranslation('map')
  const initialValues = useMemo(() => parsePoiFilter(mapFilters.advanced.$or ?? []), [mapFilters.advanced])
  const itemSchema: any = Yup.object().shape({
    name: Yup.string().required(t('map:sidebar.filters.advancedFilters.errors.requiredName')),
    operator: Yup.string().required(t('map:sidebar.filters.advancedFilters.errors.requiredOperator')),
    value: Yup.mixed().required(t('map:sidebar.filters.advancedFilters.errors.requiredValue'))
  })
  const validationSchema = Yup.object().shape({
    project: Yup.array().of(itemSchema),
    resource: Yup.array().of(itemSchema)
  })
  // handlers
  const onSubmit = useCallback(({ project, resource }) => {
    const { advanced, formatPoiFilter } = getFormatPoiFilter()

    project.forEach((filter: MapTypes.IAdvancedFilterField) => formatPoiFilter('project', filter))
    resource.forEach((filter: MapTypes.IAdvancedFilterField) => formatPoiFilter('resource', filter))

    updateMapFilters({
      ...mapFilters,
      needMapRefresh: true,
      advanced
    })
  }, [mapFilters, updateMapFilters])

  return <div data-testid='map-advanced-filters' className='h-9.5/10'>
    <AdvancedPoiFiltersForm
      defaultValues={initialValues}
      onFormSubmit={onSubmit}
      validationSchema={validationSchema}
    />
  </div>
}


export default AdvancedFilters