// React libs
import React, { FC, useContext, useState, useEffect } from 'react';
import * as Yup from 'yup';
import { now } from 'moment';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik';
// Components
import CreateProjectForm from '../../../../Form/CreateProject/CreateProjectForm';
// Contexts
import PoisContext, {
  IPoisContext,
} from '../../../../../Data/Contexts/PoisContext';
import MapConfigContext, {
  IMapConfigContext,
} from '../../../../../Data/Contexts/MapConfigContext';
import PhaseTypeContext, {
  IPhaseTypeContext,
} from '../../../../../Data/Contexts/PhaseTypeContext';
import MapFiltersContext, {
  IMapFiltersContext,
} from '../../../../../Data/Contexts/MapFiltersContext';
// Services
import MapService from '../../../../../Data/Services/MapService';
// Type
import * as Types from './CreateProjectPanel.type';
import * as AutocompleteTypes from '../../../../../../../Core/Components/UiKit/Form/Autocomplete/Autocomplete.type';
import * as FormTypes from '../../../../Form/CreateProject/CreateProjectForm.type';
import * as MapTypes from '../../../../../Data/Models/Map.type';
// Utils
import {
  handleMarkerForPoiCreation,
  creationMarkerId,
} from '../../../../../Utils/Map';
// Common
import Common from '../../../../../../../App/Resources/Common';
import CoreCommon from '../../../../../../../Core/Resources/Common';

const CreateProjectPanel: FC<Types.IProps> = ({ onCreationDone }) => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'map']);
  const history = useHistory();
  const controller = new AbortController();
  const signal = controller.signal;

  // State
  const [name, setName]: [string, Function] = useState<string>('');
  const [poiType, setPoiType]: [string, Function] = useState<string>('');
  const [address, setAddress]: [string, Function] = useState<string>('');
  const [isSearchingAddress, setIsSearchingAddress]: [
    boolean,
    Function
  ] = useState<boolean>(false);
  const [addressOptions, setAddressOptions]: [
    AutocompleteTypes.IOption[],
    Function
  ] = useState<AutocompleteTypes.IOption[]>([]);
  const [fullAddressOptions, setAddressFullOptions]: [
    any[],
    Function
  ] = useState<any[]>([]);

  // Contexts
  const { pois, updatePois }: IPoisContext = useContext(PoisContext);
  const { mapConfig }: IMapConfigContext = useContext(MapConfigContext);
  const { phaseTypes }: IPhaseTypeContext = useContext(PhaseTypeContext);
  const { mapFilters, updateMapFilters }: IMapFiltersContext = useContext(
    MapFiltersContext
  );

  // Handlers
  const handleNameSelection = (event: any) => {
    setName(event.target.value);
  };
  const handlePoiTypeSelection = (option: any) => {
    setPoiType(option?.value);
  };
  const handleAddressSelection = (
    option: MapTypes.IMapboxPlace,
    value: string
  ) => {
    const position: [number, number] = option.geometry.coordinates;
    updatePois(
      handleMarkerForPoiCreation(
        [...pois],
        position.reverse() as [number, number],
        true
      )
    );
    setAddress(value);
  };
  const handleGeocodeFieldChanged = (event: any, value: string) => {
    if (value.length > 2) {
      if (isSearchingAddress) {
        controller.abort();
      }
      setIsSearchingAddress(true);
      MapService.searchMapboxAddress(
        mapConfig?.mapConfigLayerToken || '',
        value,
        mapConfig?.mapConfigGeocodageCountry || '',
        signal
      )
        .then((results: MapTypes.IMapboxPlacesData) => {
          const data = results.features.filter((f: MapTypes.IMapboxPlace) =>
            f.place_type.includes('address')
          );
          setIsSearchingAddress(false);
          setAddressFullOptions(data);
          setAddressOptions(formatOptions(data));
        })
        .catch(() => {
          setIsSearchingAddress(false);
        });
    }
  };
  const handleSubmit = async (
    values: FormTypes.IFormValues,
    { setSubmitting }: Partial<FormikHelpers<FormTypes.IFormValues>>
  ) => {
    setSubmitting && setSubmitting(true);
    try {
      const phaseType = phaseTypes.find(
        (type: MapTypes.IPhaseType) => type.order === 1
      );
      const newAddress = await saveAddress(values);
      const newPoi = await savePoi(values, newAddress.data.id, phaseType);
      await savePoiPhases(newPoi.data.id, phaseType);
      enqueueSnackbar(t('map:header.panel.project.success'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'success',
      });
      updateMapFilters({ ...mapFilters, needMapRefresh: true });
      setSubmitting && setSubmitting(false);
      onCreationDone();
      return newPoi.data.id;
    } catch (e) {
      enqueueSnackbar(e?.error?.message || t('common:errors.defaultMessage'), {
        ...CoreCommon.Constantes.snackbarDefaultProps,
        variant: 'error',
      });
      setSubmitting && setSubmitting(false);
    }
  };
  const handleCancel = () => {
    updateMapFilters({ ...mapFilters, needMapRefresh: true });
    onCreationDone();
  };
  const handleDetails = async (
    values: FormTypes.IFormValues,
    setSubmitting: (value: boolean) => void
  ) => {
    const id = await handleSubmit(values, { setSubmitting });
    history.push(
      `/${Common.Routes.routeProject}/${id}/${Common.Routes.routeUpdateProject}`
    );
  };

  // Actions
  const formatOptions = (values: any[]) => {
    return values.reduce(
      (list: AutocompleteTypes.IOption[], item: MapTypes.IMapboxPlace) => {
        list.push({
          value: item.id,
          label: item.place_name_fr,
        });
        return list;
      },
      []
    );
  };
  const saveAddress = (values: FormTypes.IFormValues) => {
    const addr = fullAddressOptions.find((a: any) => a.id === values.address);
    const addrArr = addr.place_name_fr.split(',');
    const context =
      addr.context &&
      addr.context.find((c: any) => (c.id as string).includes('region'));
    const addrToSave: MapTypes.IAddress = {
      city: (addrArr[1] && addrArr[1].trim().split(' ')[1]) || '',
      country: addrArr[2].trim() || '',
      line1: addrArr[0] || '',
      name: `${values.name} - Addresse`,
      town: context.text_fr,
      zip: (addrArr[1] && addrArr[1].trim().split(' ')[0]) || '',
    };
    return MapService.saveAddress(addrToSave);
  };
  const savePoi = (
    values: FormTypes.IFormValues,
    addresseId: string,
    phaseType?: MapTypes.IPhaseType
  ) => {
    const addr = fullAddressOptions.find((a: any) => a.id === values.address);
    const phases = [];
    if (phaseType) {
      phases.push({ startAt: now(), type: { id: phaseType.id } });
    }
    const poiToSave: Partial<MapTypes.IPoi> = {
      fkaddress: addresseId,
      fkpoiType: values.type,
      geo: { coordinates: addr?.center.reverse(), type: 'Point' },
      isProject: true,
      name: values.name,
      phases,
    };
    return MapService.savePoi(poiToSave);
  };
  const savePoiPhases = (poiId: string, phaseType?: MapTypes.IPhaseType) => {
    const phaseToSave: MapTypes.IPoiPhase = {
      fkphaseType: phaseType?.id || '',
      fkpoi: poiId,
      startAt: new Date(),
      type: { id: phaseType?.id || '' },
    };
    return MapService.savePoiPhase(phaseToSave);
  };

  // Effects
  useEffect(() => {
    const poisData = [...pois];
    const creatingPoi = poisData.pop();
    if (creatingPoi && creatingPoi?.id === creationMarkerId) {
      const position = creatingPoi.geo.coordinates;
      MapService.searchMapboxAddress(
        mapConfig?.mapConfigLayerToken || '',
        [...position].reverse().join(','),
        mapConfig?.mapConfigGeocodageCountry || ''
      ).then((results: MapTypes.IMapboxPlacesData) => {
        const data = results.features.filter((f: MapTypes.IMapboxPlace) =>
          f.place_type.includes('address')
        );
        if (data && data.length > 0) {
          setAddressFullOptions(data);
          setAddressOptions(formatOptions(data));
          setAddress(data.length > 0 ? data[0].id : '-1');
        } else {
          enqueueSnackbar(t('map:header.panel.no_address'), {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'warning',
          });
        }
      });
    }
  }, [enqueueSnackbar, mapConfig, pois, t]);

  // Renders
  const renderForm = () => {
    const defaultValues: FormTypes.IFormValues = {
      name: name || '',
      type: poiType || '',
      address: address || '',
    };
    const validationSchema = Yup.object({
      name: Yup.string().required(
        t('map:header.panel.project.form.name.errors.required')
      ),
      type: Yup.string().required(
        t('map:header.panel.project.form.type.errors.required')
      ),
      address: Yup.string().required(
        t('map:header.panel.project.form.address.errors.required')
      ),
    });
    return (
      <CreateProjectForm
        defaultValues={defaultValues}
        onFormSubmit={handleSubmit}
        validationSchema={validationSchema}
        miscData={{ addressOptions, fullAddressOptions }}
        miscFunctions={{
          cancel: handleCancel,
          details: handleDetails,
          nameSelection: handleNameSelection,
          poiTypeSelection: handlePoiTypeSelection,
          addressSelection: handleAddressSelection,
          getAddresses: handleGeocodeFieldChanged,
        }}
      />
    );
  };
  return (
    <div data-testid='map-create-project' className='w-full'>
      {renderForm()}
    </div>
  );
};

CreateProjectPanel.propTypes = {};

export default CreateProjectPanel;
