// Utils
import { createContext } from 'react';

export interface IMapHiddenLegendContext {
  hiddenLegendMapId: string | undefined;
  updateHiddenLegendMapId: (id: string | undefined) => void;
}
const MapHiddenLegendContext = createContext<IMapHiddenLegendContext>({
  hiddenLegendMapId: undefined,
  updateHiddenLegendMapId: (id: string | undefined) => { },
});

export default MapHiddenLegendContext;
