// React libs
import PropTypes from 'prop-types';
import React, { FC, useCallback, useContext, useMemo } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
// Components
import ActiveButton from '../../../../../Core/Components/UiKit/Button/ActiveButton';
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import MapSidebarCard from './MapSidebarCard/MapSidebarCard';
import MapSidebarFilter from './MapSidebarFilter/MapSidebarFilter';
import Separator from '../../../../../Core/Components/UiKit/Separator/Separator';
import TransparentPanel from '../../../../../Core/Components/UiKit/Panel/TransparentPanel/TransparentPanel';
import Typography from '../../../../../Core/Components/UiKit/Typography/Typography';
import { useTranslation } from 'react-i18next';
// Contexts
import MapFiltersContext, { IMapFiltersContext } from '../../../Data/Contexts/MapFiltersContext';
// Type
import * as Types from './MapSidebar.type';
// Utils
import { areFiltersSelected as getAreFiltersSelected } from '../../../Utils/Map';

export const SidebarContentKeys = {
  card: 'backgroundCard',
  filters: 'poiFilters',
};

export const SideBarWidthByComponent: { [key: string]: string } = {
  [SidebarContentKeys.card]: '64',
  [SidebarContentKeys.filters]: '96'
}

const MapSidebar: FC<Types.IProps> = ({
  toggleMapSideBar,
  contentKey,
  onLayerClick,
  activeLayer,
}) => {
  // Contexts
  const { areAdvancedFiltersSelected, mapFilters, updateAreAdvancedFiltersSelected, updateAreFavoriteFiltersSelected, updateMapFilters }: IMapFiltersContext = useContext(MapFiltersContext);

  // Variables
  const sideBarContents = {
    [SidebarContentKeys.card]: (
      <MapSidebarCard onLayerClick={onLayerClick} activeLayer={activeLayer} />
    ),
    [SidebarContentKeys.filters]: <MapSidebarFilter />,
  };
  const { t } = useTranslation(['map']);
  const areFiltersSelected = useMemo(() => getAreFiltersSelected(mapFilters), [mapFilters])

  // handlers
  const toggleAdvancedFilters = useCallback(() => {
    updateAreFavoriteFiltersSelected(false)
    updateAreAdvancedFiltersSelected(!areAdvancedFiltersSelected)
  }, [areAdvancedFiltersSelected, updateAreAdvancedFiltersSelected, updateAreFavoriteFiltersSelected])
  const resetFilters = useCallback(() => updateMapFilters({
    initialized: false,
    links: false,
    markers: { links: {}, projects: {}, resources: {} },
    needMapRefresh: true,
    advanced: {}
  }), [updateMapFilters])

  return (
    <div
      className={`absolute bg-transparent flex flex-col h-full left-0 overflow-hidden top-0 w-full md:w-${SideBarWidthByComponent[contentKey]} z-1500`}
      data-testid='map-sidebar'
    >
      <TransparentPanel />
      <div className='pt-2 px-1 text-white z-10'>
        <div className='flex justify-between'>
          <div />
          <div>
            {contentKey === SidebarContentKeys.filters
              ? <ActiveButton
                handler={toggleAdvancedFilters}
                icon='filter'
                isActive={areAdvancedFiltersSelected}
                label={t(`map:sidebar.filters.${areAdvancedFiltersSelected ? 'filters' : 'advancedFilters.title'}`)}
              />
              : <Typography variant='h4' className='font-bold'>{t('map:sidebar.cards.title').toUpperCase()}</Typography>
            }
          </div>
          <div>
            {areFiltersSelected &&
              <Tooltip title={t('map:sidebar.filters.delete') || ''} className='mr-1'>
                <span>
                  <FaIcon
                    name='trash'
                    className='cursor-pointer text-xl'
                    onClick={resetFilters}
                  />
                </span>
              </Tooltip>
            }
            <FaIcon
              name='times'
              className='cursor-pointer text-2xl'
              onClick={() => toggleMapSideBar(false, '')}
            />
          </div>
        </div >
        <Separator type='horizontal' className='mt-2' />
      </div >
      <div className='flex-1 overflow-auto px-1 md:px-0 z-10'>
        {sideBarContents[contentKey]}
      </div>
    </div>
  );
};

MapSidebar.propTypes = {
  contentKey: PropTypes.string.isRequired,
  toggleMapSideBar: PropTypes.func.isRequired,
  activeLayer: PropTypes.any,
  onLayerClick: PropTypes.func.isRequired,
};

export default MapSidebar;
