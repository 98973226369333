// React libs
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
// Type
import * as Types from './ProfilUser.type';
// Layout
import BaseLayout from '../../../Components/Layout/BaseLayout/BaseLayout';
// Contexts
import UserContext from '../../../../Core/Data/Contexts/UserContext';
// Utils
import { isAdmin, isWriter } from '../../../../Core/Utils/User';
import AppSidebar from '../../../Components/Sidebar/AppSidebar/AppSidebar';
import * as FormUser from '../../../Components/Form/FormType/Auth/UserProfilForm/UserPofilForm.type';
import UserProfilForm from '../../../Components/Form/FormType/Auth/UserProfilForm/UserProfilForm';
import * as FormAddress from '../../../Components/Form/FormType/Auth/AddressProfilForm/AddressProfilForm.type';
import AddressForm from '../../../Components/Form/FormType/Auth/AddressProfilForm/AddressProfilForm';
import * as FormPassword from '../../../Components/Form/FormType/Auth/PasswordProfilForm/PasswordProfilForm.type';
import UserPasswordForm from '../../../Components/Form/FormType/Auth/PasswordProfilForm/PasswordProfilForm';
//Services
import AuthService from '../../../Data/Services/AuthService';
import LocalStorage from '../../../../Core/Data/Services/Storage/LocalStorage';
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';

import { useSnackbar } from 'notistack';
import { FormikHelpers } from 'formik';
// Common
import CoreCommon from '../../../../Core/Resources/Common';
import { Divider } from '@material-ui/core';

const ProfilUser: FC<Types.IProps> = () => {
   const { t } = useTranslation(['profil']);
   const [data , setData] = useState<any>()
   const [dataAddress , setDataAddress] = useState<any>([])
   const [accessType , setAccessType] = useState<any>()
   const [isRefresh, setIsRefresh] = useState<any>(true)

   const idUser = LocalStorage.get(LocalStorage.keys.userId)

   const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
      AuthService.getCurrentUser(idUser).then(value =>{
        setData(value)
        setIsRefresh(false)
      })
      AuthService.getAdressUser().then(value =>{
        setDataAddress(value.data.map((address:any) =>({
          label:address.name,
          value:address.id
         })
        ))
      })
      AuthService.getAccessType().then(value =>{
        setAccessType(value.data)
      })
    }, [isRefresh]);
   console.log(data)
   const defaultValues: FormUser.IFormUser = {
    firstname: data?.data.firstname,
    name: data?.data.name,
    url: data?.data.url,
    mail : data?.data.mail,
    phone: data?.data.phone,
    mobile: data?.data.mobile,
    comment: data?.data.comment,
    fkaddress: data?.data.fkaddress,
    fkaccount: data?.data.fkaccount
    };

  const defaultValuesAddress: FormAddress.IFormAdress = data?.data.address === undefined ? {} : {
    name: data.data.address.name,
    line1: data.data.address.line1,
    line2: data.data.address.line2,
    city: data.data.address.city,
    zip : data.data.address.zip,
    country: data.data.address.country,
    fkaddress: data.data.address.id
  }  

  const defaultValuesPassword: FormPassword.IFormPassword = {
    account: data?.data.account.subjectId,
    oldPassword: "",
    newPassword: "",
    confirmPassword:"",
    accesType : data?.data.account.accessType.id,
    comment: data?.data.account.comment,
  }
    const handleSubmit = (values: FormUser.IFormUser,{ setSubmitting, resetForm }: FormikHelpers<FormUser.IFormUser>) => {
      setSubmitting(true);
      AuthService.setCurrentUser(idUser, values).then((data: FormUser.IFormUser)=> {
          enqueueSnackbar(t('profil:Vos données ont bien été enregistrer'), {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'success',
          });
        }).catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:Une erreur est survenue, veuillez ressayer dans un instant...'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          })
          setSubmitting(false);
          resetForm({values:defaultValues});
        })
    };

    const handleSubmitAddress = (values: FormAddress.IFormAdress,{ setSubmitting, resetForm }: FormikHelpers<FormAddress.IFormAdress>) => {
      setSubmitting(true);
      AuthService.setCurrentUser(idUser, {fkaddress:values.fkaddress}).then((data: FormUser.IFormUser)=> {
        enqueueSnackbar(t('profil:Vos données ont bien été enregistrer'), {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          variant: 'success',
        });
        setIsRefresh(true)
      }).catch((e: CoreTypes.IWsException) => {
      enqueueSnackbar(
        e?.error?.message || t('common:Une erreur est survenue, veuillez ressayer dans un instant...'),
        {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          variant: 'error',
        })
        setSubmitting(false);
        resetForm({values:defaultValuesAddress});
      })
      
    };

    const handleSubmitPassword = (values: FormPassword.IFormPassword,{ setSubmitting, resetForm }: FormikHelpers<FormPassword.IFormPassword>) => {
      setSubmitting(true);
      AuthService.setCurrentUser(idUser, {
        account: {
          comment: values.comment,
          fkaccessType: values.accesType,
          op: values.oldPassword,
          p: values.newPassword,
          pc: values.confirmPassword,
          subjectId:values.account,
        }
      }).then((data: FormPassword.IFormPassword)=> {
          enqueueSnackbar(t('profil:Vos données ont bien été enregistrer'), {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'success',
          });
          setIsRefresh(true)
        }).catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:Une erreur est survenue, veuillez ressayer dans un instant...'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          })
          setSubmitting(false);
          resetForm({values:defaultValuesPassword});
        })
    };


    const validationSchema = Yup.object({
      firstname: Yup.string(),
      name: Yup.string(),
      url: Yup.string().nullable(),
      mail : Yup.string().email().nullable(),
      phone: Yup.string().nullable(),
      mobile: Yup.string().nullable(),
      comment: Yup.string().nullable(),
    });
    
    const validationSchemaAddress = Yup.object({
      name: Yup.string().nullable(),
      line1: Yup.string().nullable(),
      line2: Yup.string().nullable(),
      city: Yup.string().nullable(),
      zip : Yup.string().nullable(),
      country: Yup.string().nullable(),
      fkaddress:Yup.string().nullable()
    });

    const validationSchemaPassword = Yup.object({
      account: Yup.string().nullable(),
      oldPassword: Yup.string().nullable(),
      newPassword: Yup.string().required('Le nouveau password est requis'),
      confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Les mots de passe ne sont pas identique'),
      accesType : Yup.string().nullable(),
      comment: Yup.string().nullable(),
    });
    
  return <>
    <BaseLayout headerConf={{
        title: {
          label: t('Contact'),
          icon: 'address-card-o',
        },
      }}>
      <UserContext.Consumer>
        {({ user }) => (
          <div className='flex flex-1 overflow-y-visible overflow-x-hidden w-full'>
            {user && (isAdmin(user) || isWriter(user)) && <AppSidebar />}
            <div
              className={`flex-1 relative`}
              data-testid='app-layout'
            >
              {data && 
              <>
              <div className="flex w-full">
                <div className='flex flex-col w-6/12' >
                  <UserProfilForm defaultValues={defaultValues}  onFormSubmit={handleSubmit}  validationSchema={validationSchema}/>
                </div>
                <Divider orientation="vertical" flexItem/>
                <div className='flex flex-col w-6/12'>
                  <AddressForm defaultValues={defaultValuesAddress}  onFormSubmit={handleSubmitAddress}  validationSchema={validationSchemaAddress} miscData={dataAddress}/>
                </div>
              </div>
                <div>
                <UserPasswordForm defaultValues={defaultValuesPassword} onFormSubmit={handleSubmitPassword}  validationSchema={validationSchemaPassword} miscData={accessType}/>
                </div>
                </>
              }
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </BaseLayout>
  </>
}
export default ProfilUser;