// React libs
import React, { FC } from 'react';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import Autocomplete from '../../../../../Core/Components/UiKit/Form/Autocomplete/Autocomplete';
import Button from '../../../../../Core/Components/UiKit/Button/Button';
import FaIcon from '../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import GeocodingField from '../../../../../Core/Components/UiKit/Form/GeocodingField/GeocodingField';
import LocalLoader from '../../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import TextField from '../../../../../Core/Components/UiKit/Form/TextField/TextField';
// Hooks
import usePoiTypes from '../../../Data/Hooks/PoiTypes';
// Type
import * as Types from './CreateResourceForm.type';
import * as AutocompleteTypes from '../../../../../Core/Components/UiKit/Form/Autocomplete/Autocomplete.type';
import * as MapTypes from '../../../Data/Models/Map.type';
// Utils
import {
  getFormPropTypes,
  isDisabled,
} from '../../../../../Core/Utils/FormUtils';

const CreateResourceForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <CreateResourceFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}
  </Formik>
);

CreateResourceForm.propTypes = getFormPropTypes();

export default CreateResourceForm;

const CreateResourceFormikForm = ({
  errors,
  isSubmitting,
  miscData,
  miscFunctions,
  setFieldTouched,
  setSubmitting,
  values,
}: FormikProps<any> & any) => {
  // Variables
  const { t } = useTranslation(['common', 'map']);
  const fieldContainerClasses = 'flex items-center mx-2';
  const fieldContainerFieldClasses = `${fieldContainerClasses} flex-1`;

  // Hooks
  const poiTypes = usePoiTypes('resource');

  return (
    <Form data-testid='map-create-project-form' className='flex w-full'>
      {poiTypes.isLoading ? (
        <div className='flex h-full items-center justify-center w-full'>
          <LocalLoader message={t('map:loading.poiTypes')} />
        </div>
      ) : (
        <div className='flex flex-1 items-center -mx-2'>
          <div className={`${fieldContainerFieldClasses}`}>
            <Field
              id='name'
              name='name'
              component={TextField}
              label={t('map:header.panel.resource.form.name.label')}
              color='primary'
              className='flex-1'
              onBlur={(event: any) => {
                setFieldTouched('name', true, true);
                miscFunctions?.nameSelection(event);
              }}
            />
          </div>
          <div className={`${fieldContainerFieldClasses}`}>
            {poiTypes.data && (
              <Field
                id='type'
                name='type'
                label={t('map:header.panel.resource.form.type.label')}
                component={Autocomplete}
                onSelectValue={(data: any) => {
                  setFieldTouched('type', true);
                  miscFunctions?.poiTypeSelection(data);
                }}
                options={poiTypes.data.reduce(
                  (list: AutocompleteTypes.IOption[], t: MapTypes.IPoiType) => {
                    list.push({ label: t.label, value: t.id });
                    return list;
                  },
                  []
                )}
                color='primary'
                className='flex-1'
              />
            )}
          </div>
          <div className={`${fieldContainerFieldClasses}`}>
            <Field
              id='address'
              name='address'
              component={GeocodingField}
              handlerSelectedValue={(data: any) => {
                setFieldTouched('address', true);
                miscFunctions?.addressSelection(data);
              }}
              label={t('map:header.panel.resource.form.address.label')}
              color='primary'
              className='flex-1'
              onInputChange={miscFunctions?.getAddresses}
              options={miscData?.addressOptions || []}
              fullOptions={miscData?.fullAddressOptions || []}
            />
          </div>
          <div className={`${fieldContainerClasses}`}>
            <Button
              onClick={miscFunctions?.cancel}
              variant='contained'
              size='large'
              className='mx-2'
            >
              <FaIcon name='times' className='text-xl' />
            </Button>
            <Button
              type='submit'
              variant='contained'
              size='large'
              className='mx-2'
              color='secondary'
              disabled={isDisabled({}, isSubmitting)}
            >
              <FaIcon name='plus' className='text-xl' />
            </Button>
            <Button
              onClick={() => miscFunctions?.details(values, setSubmitting)}
              variant='contained'
              size='large'
              className='mx-2'
              color='secondary'
              disabled={isDisabled({}, isSubmitting)}
            >
              <FaIcon name='id-card-o' className='text-xl' />
            </Button>
          </div>
        </div>
      )}
    </Form>
  );
};
