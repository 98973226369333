// React libs
import React, { FC, useState, useCallback, useContext } from 'react';
import * as Yup from 'yup';
import { Form, FormikProps, Formik, Field } from 'formik'
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Tooltip } from '@material-ui/core'
// Components
import Button from '../../../../../../../Core/Components/UiKit/Button/Button'
import DeleteSelectionConfirmModal from '../../../../Modales/DeleteSelection/DeleteSelectionConfirmModal'
import FaIcon from '../../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon'
import LocalLoader from '../../../../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import TextField from '../../../../../../../Core/Components/UiKit/Form/TextField/TextField'
// Contexts
import ActiveLayerContext, { IActiveLayerContext } from '../../../../../Data/Contexts/ActiveLayerContext';
import MapConfigContext, { IMapConfigContext } from '../../../../../Data/Contexts/MapConfigContext';
import MapFiltersContext, { IMapFiltersContext } from '../../../../../Data/Contexts/MapFiltersContext';
import UserContext, { IUserContext } from '../../../../../../../Core/Data/Contexts/UserContext';
// Services
import MapService from '../../../../../Data/Services/MapService'
// Types
import * as CoreTypes from '../../../../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../../../../../Data/Models/Map.type';
import * as Types from './FavoriteFilters.type'
// Utils
import useConfirmModal from '../../../../../../../Core/Utils/useConfirmModal'
import { formatMapFilters, parseMapFilters } from '../../../../../Utils/Map'
// Common
import CoreCommon from '../../../../../../../Core/Resources/Common';
// Hooks
import useSelections from '../../../../../Data/Hooks/Selections'

interface IFavoriteFiltersForm {
  enableDefaultView: () => void
  selection?: MapTypes.ISelection
}

const FavoriteFiltersForm: FC<IFavoriteFiltersForm> = ({ enableDefaultView, selection }) => {
  // Variables
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['map', 'common'])
  const initialValues = {
    name: selection?.label ?? ''
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('map:sidebar.filters.favoriteFilter.errors.requiredName')),
  })

  // Contexts
  const { activeLayer }: IActiveLayerContext = useContext(ActiveLayerContext)
  const { mapFilters }: IMapFiltersContext = useContext(MapFiltersContext)
  const { mapConfig }: IMapConfigContext = useContext(MapConfigContext)
  const { user }: IUserContext = useContext(UserContext)

  // Actions
  const saveSelection = useCallback(async name => {
    const props = {
      data: {
        mapView: {
          center: JSON.parse(mapConfig?.mapConfigCenter ?? ''),
          zoom: mapConfig?.mapConfigZoom
        },
        selection: formatMapFilters(mapFilters),
      },
      fkaccount: user?.profile.person.account.id,
      fklayerMap: activeLayer?.id ?? mapConfig?.layerMap.id,
      label: name
    }
    const promise = selection !== undefined ? MapService.updateSelection(selection.id, props) : MapService.saveSelection(props)
    await promise.catch((e: CoreTypes.IWsException) => {
      enqueueSnackbar(
        e?.error?.message || t('common:errors.defaultMessage'),
        {
          ...CoreCommon.Constantes.snackbarDefaultProps,
          variant: 'error',
        }
      );
    })
  }, [activeLayer, enqueueSnackbar, mapConfig, mapFilters, selection, t, user])

  // handlers
  const onSubmit = useCallback(({ name }, actions) => {
    saveSelection(name).finally(() => {
      actions.setSubmitting(false)
      enableDefaultView()
    })
  }, [saveSelection, enableDefaultView])

  return <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
  >
    {(formikProps: FormikProps<any>) => (
      <Form>
        <Field
          name='name'
          component={TextField}
          label={t('map:sidebar.filters.favoriteFilter.name')}
        />
        <div className='absolute bottom-0 p-2 w-full text-center'>
          <Button
            className='mr-2 text-selection hover:bg-active-hover hover:text-selection-inverse'
            color='primary'
            size='small'
            onClick={() => enableDefaultView()}
            startIcon={<FaIcon name='arrow-circle-left' className='text-lg' />}
          >
            {t('map:sidebar.filters.favoriteFilter.cancel')}
          </Button>
          <Button
            className='hover:bg-active-hover'
            type='submit'
            color='secondary'
            size='small'
            disabled={formikProps.isSubmitting}
            startIcon={
              formikProps.isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : <FaIcon name='plus-circle' className='text-lg' />
            }
          >
            {t('map:sidebar.filters.favoriteFilter.save')}
          </Button>
        </div>
      </Form>
    )}
  </Formik>
}

interface IFavoriteFiltersVue {
  enableEditView: (selection?: MapTypes.ISelection) => void
}

const FavoriteFiltersVue: FC<IFavoriteFiltersVue> = ({ enableEditView }) => {
  // Variables
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['map', 'common'])
  const selections = useSelections()
  const deleteSelectionModal = useConfirmModal()

  // Contexts
  const { mapFilters, updateMapFilters }: IMapFiltersContext = useContext(MapFiltersContext)
  const { mapConfig, updateMapConfig }: IMapConfigContext = useContext(MapConfigContext)
  const { setActiveLayer }: IActiveLayerContext = useContext(ActiveLayerContext)

  // State
  const [activeSelection, setActiveSelection] = useState<string | undefined>(undefined)

  // Handlers
  const onEdit = useCallback(selection => {
    enableEditView(selection)
  }, [enableEditView])
  const onDelete = useCallback(selection => {
    deleteSelectionModal.openModal().then(() => {
      MapService.deleteSelection(selection.id).catch((e: CoreTypes.IWsException) => {
        enqueueSnackbar(
          e?.error?.message || t('common:errors.defaultMessage'),
          {
            ...CoreCommon.Constantes.snackbarDefaultProps,
            variant: 'error',
          }
        );
      }).finally(() => selections.refresh())
    }, () => { })
  }, [deleteSelectionModal, enqueueSnackbar, selections, t])
  const onApply = useCallback(selection => {
    if (activeSelection === selection.id) {
      setActiveSelection(undefined)

      updateMapFilters({
        initialized: false,
        needMapRefresh: true,
        links: false,
        markers: { links: {}, projects: {}, resources: {} },
        advanced: {}
      })
      updateMapConfig(undefined)
      setActiveLayer(undefined)
    } else {
      setActiveSelection(selection.id)

      const data = JSON.parse(selection.data)
      updateMapFilters({
        ...mapFilters,
        needMapRefresh: true,
        ...parseMapFilters(data.selection)
      })
      updateMapConfig(mapConfig && {
        ...mapConfig,
        mapConfigCenter: JSON.stringify(data.mapView.center),
        mapConfigZoom: data.mapView.zoom
      })

      setActiveLayer(selection.layerMap)
    }
  }, [activeSelection, mapConfig, mapFilters, setActiveLayer, setActiveSelection, updateMapConfig, updateMapFilters])

  if (selections.isLoading) {
    return <div className='flex h-full items-center justify-center w-full'>
      <LocalLoader message={t('map:sidebar.filters.favoriteFilter.loading.selections')} />
    </div>
  }

  return <div>
    <Button
      color='secondary'
      size='small'
      className='w-full'
      onClick={() => enableEditView()}
      startIcon={<FaIcon name='plus-circle' className='text-lg' />}
    >
      {t('map:sidebar.filters.favoriteFilter.saveCurrentMap')}
    </Button>
    <List dense className='p-0'>
      {selections.data?.map(selection =>
        <ListItem key={selection.id} classes={{
          root: 'bg-main-light text-selection'
        }}>
          <ListItemText primary={selection.label} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit" size='small' className='mr-1' onClick={() => onEdit(selection)}>
              <Tooltip title={t('map:sidebar.filters.favoriteFilter.tooltips.edit') ?? ''}>
                <div>
                  <FaIcon name='pencil' />
                </div>
              </Tooltip>
            </IconButton>
            <IconButton edge="end" aria-label="delete" size='small' className='mr-1' onClick={() => onDelete(selection)}>
              <Tooltip title={t('map:sidebar.filters.favoriteFilter.tooltips.delete') ?? ''}>
                <div>
                  <FaIcon name='trash' />
                </div>
              </Tooltip>
            </IconButton>
            <IconButton edge="end" aria-label="apply" size='small' className={`mr-1 hover:text-active-hover ${activeSelection === selection.id ? 'text-active' : ''}`} onClick={() => onApply(selection)}>
              <Tooltip title={t('map:sidebar.filters.favoriteFilter.tooltips.apply') ?? ''}>
                <div>
                  <FaIcon name='eye' />
                </div>
              </Tooltip>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>)}
    </List>

    <DeleteSelectionConfirmModal
      handleClose={deleteSelectionModal.onClose}
      isOpened={deleteSelectionModal.isOpened}
    />
  </div>
}

const FavoriteFilters: FC<Types.IProps> = () => {
  // State
  const [activeView, setActiveView] = useState<'default' | 'edit'>('default')
  const [selectedSelection, setSelectedSelection] = useState<MapTypes.ISelection | undefined>()
  // handlers
  const enableEditView = useCallback(selection => {
    setSelectedSelection(selection)
    setActiveView('edit')
  }, [])
  const enableDefaultView = useCallback(() => {
    setActiveView('default')
  }, [])

  const views = {
    'edit': <FavoriteFiltersForm enableDefaultView={enableDefaultView} selection={selectedSelection} />,
    'default': <FavoriteFiltersVue enableEditView={enableEditView} />
  }

  return <div data-testid='map-sidebar-favorite-filters'>
    {views[activeView]}
  </div>
}

export default FavoriteFilters