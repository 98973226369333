// Utils
import ServicesUtils from '../../Utils/Services';
import CommonUrls from './CommonUrls';

export default class CommonService {
  /**
   * IMAGES
   */
  static uploadImage(data: any) {
    return ServicesUtils.postFormData(CommonUrls.uploadImage(), data, false);
  }
  static deleteImage(id: string) {
    return ServicesUtils.delete(CommonUrls.deleteImage(id), false);
  }
  static deleteImageLegend(fklayerMapId: string, legendtype: string) {
    return ServicesUtils.delete(
      CommonUrls.deleteLayerMapImageLegend(fklayerMapId, legendtype),
      false
    );
  }

  // Data
  static getPoiTypes() {
    return ServicesUtils.get(CommonUrls.getPoiTypes(), false);
  }
  static getThematic() {
    return ServicesUtils.get(CommonUrls.getThematic(), false)
  }
  static getTerritories() {
    return ServicesUtils.get(CommonUrls.getTerritories(), false)
  }
}
