// React libs
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useContext,
  useCallback,
  Fragment,
} from 'react';
import ReactDOMServer from 'react-dom/server';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { pdf, Image, Text, View } from '@react-pdf/renderer';
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet';
import L from 'leaflet';
import keyBy from 'lodash/keyBy';
// Components
import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import PdfDocument from '../../../../Core/Components/UiKit/PdfDocument/PdfDocument';
import PageLoader from '../../../../Core/Components/UiKit/Loader/PageLoader/PageLoader';
import Typography from '../../../../Core/Components/UiKit/Typography/Typography';
import ConfirmModale from '../../../../Core/Components/UiKit/Modales/ConfirmModale/ConfirmModale';
import MapLegend from './MapLegend/MapLegend';
// Context
import MapConfigContext from '../../Data/Contexts/MapConfigContext';
import PoisContext from '../../Data/Contexts/PoisContext';
import PhaseTypeContext from '../../Data/Contexts/PhaseTypeContext';
import PoiTypeContext from '../../Data/Contexts/PoiTypesContext';
import LinkTypeContext from '../../Data/Contexts/LinkTypesContext';
import PoisLinksContext from '../../Data/Contexts/PoisLinksContext';
import UserContext from '../../../../Core/Data/Contexts/UserContext';
import MapHiddenLegendContext, { IMapHiddenLegendContext } from '../../Data/Contexts/MapHiddenLegendContext';
import MapFiltersContext from '../../Data/Contexts/MapFiltersContext';
// Services
import MapService from '../../Data/Services/MapService';
// Type
import * as Types from './MapCanvas.type';
import * as MapTypes from '../../Data/Models/Map.type';
import * as CoreTypes from '../../../../Core/Data/Models/Core.type';
// Utils
import {
  handleMarkerForPoiCreation,
  translateStringCenter,
  creationMarkerId,
  getMarkerImage,
} from '../../Utils/Map';
import { createClusterDonut } from './ClusterDonut/ClusterDonut';
import { KLink } from './MarkersLink/MarkersLink';
import { isReader } from '../../../../Core/Utils/User';
// Common
import Common from '../../../../App/Resources/Common';
import CoreCommon from '../../../../Core/Resources/Common';
// Css
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
// Misc
require('leaflet.markercluster/dist/leaflet.markercluster.js');

const defaultCenter: [number, number] = [45.7601609, 4.8522794];

const MapCanvas = forwardRef(
  (
    {
      currentCenter,
      currentZoom,
      isCreatingPoiOnMap,
      layer,
      onHomeClick,
      title,
    }: Types.IProps,
    ref
  ) => {
    // Variables
    const defaultLayerUrl =
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    const defaultZoom = 8;
    const { t } = useTranslation(['map']);
    const history = useHistory();
    const leafletHomeButtonClass = 'leaflet-control-zoom-home'
    const { enqueueSnackbar } = useSnackbar();

    // Contexts
    const { mapConfig } = useContext(MapConfigContext);
    const { pois, updatePois } = useContext(PoisContext);
    const { phaseTypes } = useContext(PhaseTypeContext);
    const { poiTypes } = useContext(PoiTypeContext);
    const { linkTypes } = useContext(LinkTypeContext);
    const { poisLinks } = useContext(PoisLinksContext);
    const { user } = useContext(UserContext);
    const { mapFilters, updateMapFilters } = useContext(MapFiltersContext);
    const { hiddenLegendMapId }: IMapHiddenLegendContext = useContext(MapHiddenLegendContext)

    // State
    const [center, setCenter]: [[number, number], Function] = useState(
      defaultCenter
    );
    const [map, setMap]: [any, Function] = useState<any>(undefined);
    const [zoom, setZoom]: [number, Function] = useState<number>(defaultZoom);
    const [layerUrl, setLayerUrl]: [string, Function] = useState<string>(
      defaultLayerUrl
    );
    const [legendData, setLegendData]: [{ data: any[] }, Function] = useState<{
      data: any[];
    }>({ data: [] });
    const [isExporting, setIsExporting]: [
      'png' | 'pdf' | '',
      Function
    ] = useState('');

    const [isHidenWhenExport, setIsHidenWhenExport]: [
      boolean,
      Function
    ] = useState(false);

    const [isPoiCreating, setPoiCreating]: [
      string | undefined,
      Function
    ] = useState<string | undefined>(undefined);
    const [homeBtn, setHomeBtn]: [
      HTMLAnchorElement | undefined,
      Function
    ] = useState<HTMLAnchorElement | undefined>(undefined);
    const [displayedLinks, setDisplayedLinks]: [any, Function] = useState<any>(
      {}
    );
    const [displayedMarkers, setDisplayedMarkers]: [any, Function] = useState<
      any
    >({});
    const [displayedClusters, setDisplayedClusters]: [any, Function] = useState<
      any
    >({});
    const [markerDeleting, setMarkerDeleting]: [
      MapTypes.IPoi | undefined,
      Function
    ] = useState<MapTypes.IPoi | undefined>(undefined);

    // Derived data
    const legendDataByType: { [key: string]: MapTypes.ILayerMapImage } = React.useMemo(() => keyBy(legendData.data, 'legendType'), [legendData])

    // Refs
    const layerRef = useRef(null);
    const linksRef = React.useRef(displayedLinks);
    const updateDisplayedLinks = (data: any) => {
      linksRef.current = data;
      setDisplayedLinks(data);
    };
    const markersRef = React.useRef(displayedMarkers);
    const updateDisplayedMarkers = (data: any) => {
      markersRef.current = data;
      setDisplayedMarkers(data);
    };
    const poisLinksRef = React.useRef(poisLinks);

    // Handlers
    const onMapCreated = (map: any) => {
      map.on('click', mapClickedCallback);
      setMap(map);
      const zoomBtnList = document.getElementsByClassName(
        'leaflet-control-zoom-in'
      );
      if (zoomBtnList && zoomBtnList.length > 0) {
        createHomeButton(zoomBtnList);
      }
    };
    const markerDoubleClickBinding = (p: MapTypes.IPoi) => {
      if (p.isProject) {
        history.push(
          `/${Common.Routes.routeProject}/${p.id}/${Common.Routes.routeUpdateProject}`
        );
      } else {
        history.push(
          `/${Common.Routes.routeResource}/${p.id}/${Common.Routes.routeUpdateResource}`
        );
      }
    };
    const getMarkerPopupContent = (p: MapTypes.IPoi) => {
      interface IMenuItem {
        id: string;
        action: (...args: any) => void;
        icon: string;
        tooltip: string;
      }

      const menuConf: IMenuItem[] = [];
      const moreOptionsMenuconf: IMenuItem[] = [];

      menuConf.push({
        id: 'info',
        action: (p: MapTypes.IPoi) => {
          if (p.isProject) {
            history.push(
              `/${Common.Routes.routeProject}/${p.id}/${Common.Routes.routePreviewProject}`
            );
          } else {
            history.push(
              `/${Common.Routes.routeResource}/${p.id}/${Common.Routes.routePreviewResource}`
            );
          }
        },
        icon: 'info',
        tooltip: t('map:marker.popup.info.tooltip'),
      });

      menuConf.push({
        id: 'link',
        action: (p: MapTypes.IPoi) => {
          console.log('link ', p);
        },
        icon: 'link',
        tooltip: t('map:marker.popup.link.tooltip'),
      });

      menuConf.push({
        id: 'ecosystem',
        action: (p: MapTypes.IPoi) => {
          console.log('ecosystem ', p);
        },
        icon: 'sitemap',
        tooltip: t('map:marker.popup.ecosystem.tooltip'),
      });

      if (user && !isReader(user)) {
        menuConf.push({
          id: 'favorite',
          action: (p: MapTypes.IPoi) => {
            console.log('favorite ', p);
          },
          icon: 'star-half-o', // Update icon if favorite or not favorite
          tooltip: t('map:marker.popup.favorite.tooltip'),
        });
        if (p.createdBy === user.login) {
          moreOptionsMenuconf.push({
            id: 'move',
            action: (p: MapTypes.IPoi) => {
              console.log('move', p);
            },
            icon: 'map-marker',
            tooltip: t('map:marker.popup.other.move.label'),
          });
          moreOptionsMenuconf.push({
            id: 'delete',
            action: (p: MapTypes.IPoi) => {
              setMarkerDeleting(p);
            },
            icon: 'trash',
            tooltip: t('map:marker.popup.other.delete.label'),
          });
        }
      }

      const menuClasses =
        'bg-red-610 cursor-pointer flex h-12 items-center justify-center mx-1 rounded-full w-12';
      const container = document.createElement('div');
      container.setAttribute('class', 'flex flex-col items-center');
      const title = ReactDOMServer.renderToStaticMarkup(
        <Typography variant='subtitle1'>{p.name}</Typography>
      );
      container.innerHTML = title;
      const menu = document.createElement('div');
      menu.setAttribute(
        'class',
        'flex items-center justify-center mt-2 relative'
      );
      menuConf.forEach(c => {
        const element = document.createElement('div');
        element.setAttribute('class', menuClasses);
        element.addEventListener('click', () => c.action(p));
        const icon = ReactDOMServer.renderToStaticMarkup(
          <FaIcon name={c.icon} className='text-white text-xl' />
        );
        element.innerHTML = icon;
        const tooltip = document.createElement('div');
        tooltip.innerHTML = c.tooltip;
        tooltip.setAttribute(
          'class',
          'raw-tooltip absolute bg-selection flex hidden items-center justify-center top-13 px-4 py-2 rounded text-white z-50'
        );
        element.addEventListener('mouseover', () => {
          tooltip.classList.remove('hidden');
        });

        element.addEventListener('mouseout', () => {
          tooltip.classList.add('hidden');
        });
        element.append(tooltip);
        menu.append(element);
      });

      if (moreOptionsMenuconf.length > 0) {
        const moreOptionsBtn = document.createElement('div');
        moreOptionsBtn.setAttribute('class', `moreOptionsBtn ${menuClasses}`);
        moreOptionsBtn.addEventListener('click', event => {
          const e = document.getElementById(moreOptionsMenuId);
          if (!e?.classList.contains('hidden')) {
            e?.classList.add('hidden');
          } else {
            e?.classList.remove('hidden');
          }
          event.stopPropagation();
        });
        window.addEventListener('click', (event: any) => {
          const needHide = !event.target.matches('.moreOptionsBtn');
          if (needHide) {
            const e = document.getElementById(moreOptionsMenuId);
            if (!e?.classList.contains('hidden')) {
              e?.classList.add('hidden');
            }
          }
        });
        const icon = ReactDOMServer.renderToStaticMarkup(
          <FaIcon name='ellipsis-v' className='text-white text-xl' />
        );
        moreOptionsBtn.innerHTML = icon;
        menu.append(moreOptionsBtn);

        const moreOptionsMenuId = 'more-options-menu';
        const moreOptionsMenu = document.createElement('div');
        moreOptionsMenu.setAttribute('id', moreOptionsMenuId);
        moreOptionsMenu.setAttribute(
          'class',
          'absolute bg-selection hidden min-w-40 right-0 rounded shadow top-13 z-50'
        );
        moreOptionsMenuconf.forEach((m: IMenuItem) => {
          const element = document.createElement('div');
          element.setAttribute(
            'class',
            'cursor-pointer flex items-center px-2 py-1'
          );
          element.addEventListener('click', () => m.action(p));
          const icon = document.createElement('div');
          icon.innerHTML = ReactDOMServer.renderToStaticMarkup(
            <FaIcon name={m.icon} className='mr-1 text-white text-xl w-4' />
          );
          element.append(icon);
          const label = document.createElement('div');
          label.setAttribute('class', 'text-white text-sm');
          label.innerHTML = m.tooltip;
          element.append(label);
          moreOptionsMenu.append(element);
        });
        menu.append(moreOptionsMenu);
      }

      container.append(menu);
      return container;
    };
    const handlerMarkerDeletingConfirmClose = (
      id: string | undefined,
      result: boolean
    ) => {
      setMarkerDeleting(undefined);
      if (result && id) {
        deleteMarker(id);
      }
    };
    const deleteMarker = async (id: string) => {
      const poiToDelete = pois.find((p: MapTypes.IPoi) => p.id === id);
      if (poiToDelete) {
        const poiDetailsData: {
          data: MapTypes.IPoi;
        } = await MapService.getPoiDetails(poiToDelete.id);
        const phases = poiDetailsData.data.phases;
        for (let i = 0; i < phases.length; i++) {
          await MapService.deletePoiPhase(phases[i].id);
        }
        MapService.deletePoi(poiToDelete.id)
          .then(() => {
            enqueueSnackbar(t('map:marker.popup.other.delete.success'), {
              ...CoreCommon.Constantes.snackbarDefaultProps,
              variant: 'success',
            });
            updateMapFilters({ ...mapFilters, needMapRefresh: true });
          })
          .catch((e: CoreTypes.IWsException) => {
            enqueueSnackbar(
              e?.error?.message || t('common:errors.defaultMessage'),
              {
                ...CoreCommon.Constantes.snackbarDefaultProps,
                variant: 'error',
              }
            );
          });
      }
    };

    // Callbacks
    const mapClickedCallback = useCallback(
      (event: any) => {
        if (isCreatingPoiOnMap) {
          const position: [number, number] = [
            event.latlng.lat,
            event.latlng.lng,
          ];
          updatePois(
            handleMarkerForPoiCreation(
              [...pois],
              position,
              isCreatingPoiOnMap === 'project'
            )
          );

          setCenter(position);
          if (map) {
            map.setView(position, zoom);
          }
        }
      },
      [isCreatingPoiOnMap, map, pois, updatePois, zoom]
    );
    const homeClickedCallback = useCallback(() => {
      if (map) {
        map.setView(
          translateStringCenter(mapConfig?.mapConfigCenter || ''),
          mapConfig?.mapConfigZoom
        );
        onHomeClick();
      }
    }, [map, mapConfig, onHomeClick]);

    const createLinksCallback = useCallback(
      (
        links: MapTypes.IPoiLink[],
        linkTypes: MapTypes.ILinkType[],
        clusters?: any
      ) => {
        const markers = markersRef.current;
        const linksList: any = {};
        links.forEach((l: MapTypes.IPoiLink) => {
          if (markers[l.fkpoiFrom] && markers[l.fkpoiTo]) {
            const linkType = linkTypes.find(
              (t: MapTypes.ILinkType) => t.id === l.fklinkType
            );

            if (map) {
              const key = `${l.fklinkType}---${l.fkpoiFrom}--${l.fkpoiTo}`;

              const markersLink = new KLink({
                originMarker: markers[l.fkpoiFrom],
                destMarker: markers[l.fkpoiTo],
                map,
                clusters,
                options: {
                  ...(linkType?.style.data || {
                    color: '#dddddd',
                  }),
                },
              });
              linksList[key] = markersLink;
            }
          }
        });
        return linksList;
      },
      [linkTypes, map, poisLinksRef]
    );

    const redrawLinksCallback = useCallback(
      (
        poisLinks: MapTypes.IPoiLink[],
        linkTypes: MapTypes.ILinkType[],
        clusters: any
      ) => {
        if (map) {
          Object.keys(linksRef.current).forEach(key =>
            linksRef.current[key].removeFrom(map)
          );
        }

        const layers = clusters._featureGroup._layers;
        const newLinks = createLinksCallback(
          poisLinks,
          linkTypes,
          Object.keys(layers)
            .filter((k: string) => layers[k]?.cluster === true)
            .map((key: string) => layers[key])
        );
        Object.keys(newLinks).forEach(key => newLinks[key].addTo(map));
        setDisplayedLinks(newLinks);
        updateDisplayedLinks(newLinks);
      },
      [createLinksCallback, map]
    );

    const createClustersCallback = useCallback(() => {
      const clusterOptions = {
        showCoverageOnHover: false,
        maxClusterRadius: 55,
        removeOutsideVisibleBounds: true,
        chunkedLoading: true,
        iconCreateFunction: (cluster: any) => {
          cluster.colors = {};
          cluster.ContainedPOIs = {};
          cluster.getAllChildMarkers().forEach((marker: any) => {
            const color = marker.options.icon.options.color;
            const PoiType = marker.PoiType;
            if (!(color in cluster.colors)) cluster.colors[color] = [];
            if (!(PoiType in cluster.ContainedPOIs))
              cluster.ContainedPOIs[PoiType] = [];
            cluster.ContainedPOIs[PoiType].push(marker.Kid);
            cluster.colors[color].push(marker.Kid);
            cluster.cluster = true;
          });
          const data = [];
          for (let color in cluster.colors) {
            data.push({
              value: cluster.colors[color].length,
              name: color,
              color: color,
            });
          }
          const donutHtml = createClusterDonut({
            size: 50,
            weight: 25,
            position: 'center',
            data: data,
            text: {
              text: cluster.getChildCount(),
              align: 'center',
            },
          });
          const htmlCluster =
            '<div class="clusterDonut">' + donutHtml.outerHTML + '</div>';
          return L.divIcon({ html: htmlCluster });
        },
      };
      return (L as any).markerClusterGroup(clusterOptions);
    }, []);

    const createMarkersCallback = useCallback(() => {
      const markersList: any = {};
      pois.forEach((p: MapTypes.IPoi) => {
        const icon: any = {
          className: 'marker-icon',
          html: '',
        };
        const markerImage = getMarkerImage(
          p,
          poisLinks,
          phaseTypes,
          poiTypes,
          linkTypes
        );
        if (markerImage) {
          icon.iconSize = new L.Point(markerImage.size[0], markerImage.size[1]);
          icon.iconAnchor = new L.Point(
            Math.round(markerImage.size[0] / 2),
            markerImage.size[1]
          );
          icon.tooltipAnchor = new L.Point(
            Math.round(markerImage.size[0] / 3),
            -Math.round(markerImage.size[0] / 2)
          );
          icon.html = ReactDOMServer.renderToString(markerImage.icon);
          icon.color = markerImage.color;
        }
        const marker = L.marker(p.geo.coordinates, {
          icon: L.divIcon(icon),
        } as any).bindTooltip(p.name);
        marker.on('mouseover', () => {
          marker.openTooltip();
        });
        marker.on('mouseout', () => {
          marker.closeTooltip();
        });
        marker.on('dblclick', () => markerDoubleClickBinding(p));
        marker.bindPopup(getMarkerPopupContent(p), {
          closeButton: false,
          className: 'marker-menu',
          // maxWidth: 500,
          // minWidth: 500,
          offset: [0, -(marker.options.icon?.options.iconSize as any)?.y || 0],
        });
        (marker as any).Kid = p.id;
        (marker as any).PoiType = p.fkpoiType;
        markersList[p.id] = marker;
      });
      return markersList;
    }, [linkTypes, map, phaseTypes, poiTypes, pois, poisLinks]);

    // Effects
    useImperativeHandle(ref, () => ({
      export(output: 'pdf' | 'png') {
        if (output === 'png') {
          exportAsPng();
        } else if (output === 'pdf') {
          exportAsPdf();
        }
      },
    }));
    useEffect(() => {
      // --> Layer
      let newLayerUrl = defaultLayerUrl;
      let newLegendData = {};
      if (layer) {
        newLayerUrl = layer?.url;
        newLegendData = layer?.images ? { data: layer.images } : {};
      } else {
        newLayerUrl = mapConfig?.layerMap?.url || defaultLayerUrl;
        newLegendData = mapConfig?.layerMap?.images
          ? { data: mapConfig?.layerMap?.images }
          : {};
      }
      (layerRef?.current as any)?.setUrl(newLayerUrl);
      setLayerUrl(newLayerUrl);
      setLegendData(newLegendData);
    }, [layer, layerRef, mapConfig, setLayerUrl, setLegendData]);

    useEffect(() => {
      // --> Center
      let newCenter = defaultCenter;
      let centerConfig = mapConfig?.mapConfigCenter;
      if (currentCenter) {
        newCenter = currentCenter;
      } else if (centerConfig && centerConfig !== '') {
        centerConfig = centerConfig.replace('[', '').replace(']', '');
        const centerArray = centerConfig
          .split(',')
          .map((v: string) => parseFloat(v));
        newCenter =
          centerArray && centerArray.length > 1
            ? [centerArray[0], centerArray[1]]
            : defaultCenter;
      }
      setCenter(newCenter);

      // --> Zoom
      let newZoom = defaultZoom;
      let zoomConfig = mapConfig?.mapConfigZoom;
      if (currentZoom) {
        newZoom = currentZoom;
      } else if (zoomConfig) {
        newZoom = zoomConfig;
      }
      setZoom(newZoom);
      if (map) {
        map.setView(newCenter, newZoom);
      }
    }, [currentCenter, currentZoom, map, mapConfig]);
    useEffect(() => {
      if (typeof isCreatingPoiOnMap !== 'undefined') {
        setPoiCreating(isCreatingPoiOnMap);
        if (map) {
          map.off('click');
          map.on('click', (event: any) => mapClickedCallback(event));
        }
      } else {
        if (map) {
          map.off('click');
        }
      }
    }, [isCreatingPoiOnMap, isPoiCreating, map, mapClickedCallback]);
    useEffect(() => {
      if (homeBtn) {
        homeBtn.removeEventListener('click', homeClickedCallback);
        homeBtn.addEventListener('click', homeClickedCallback);
      }
    }, [homeBtn, homeClickedCallback]);
    useEffect(() => {
      const creationMarker = [...pois].find(
        (p: MapTypes.IPoi) => p.id === creationMarkerId
      );
      if (creationMarker) {
        setCenter(creationMarker.geo.coordinates);
      }
    }, [pois]);
    useEffect(
      function() {
        if (map) {
          Object.keys(displayedClusters).forEach(key =>
            displayedClusters[key].removeFrom(map)
          );
          Object.keys(displayedLinks).forEach(key =>
            displayedLinks[key].removeFrom(map)
          );
        }
        // cluster
        const cluster = createClustersCallback();
        // markers
        const markers = createMarkersCallback();
        setDisplayedMarkers(markers);
        updateDisplayedMarkers(markers);
        Object.keys(markers).forEach(key => cluster.addLayer(markers[key]));
        // links
        const links = createLinksCallback(poisLinks, linkTypes);
        setDisplayedLinks(links);
        updateDisplayedLinks(links);
        cluster.on('animationend', (e: any) => {
          redrawLinksCallback(poisLinks, linkTypes, e.target);
        });
        setDisplayedClusters({ cluster: cluster });
        if (map) {
          cluster.addTo(map);
          Object.keys(links).forEach(key => links[key].addTo(map));
          redrawLinksCallback(poisLinks, linkTypes, cluster);
        }
      },
      [
        createClustersCallback,
        createLinksCallback,
        createMarkersCallback,
        map,
        redrawLinksCallback,
      ]
    );
    // Misc
    const createHomeButton = useCallback((zoomBtnList: any) => {
      const zoomBtn = zoomBtnList[0];
      const btn: HTMLAnchorElement = document.createElement('a');
      btn.setAttribute('class', leafletHomeButtonClass);
      btn.setAttribute('href', '#');
      btn.setAttribute('role', 'button');
      btn.setAttribute('aria-label', 'Home');
      btn.setAttribute('title', 'Home');
      btn.setAttribute('id', 'home-redirection');
      btn.innerHTML =
        '<svg class="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 5.69l5 4.5V18h-2v-6H9v6H7v-7.81l5-4.5M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3z"></path></svg>';
      btn.addEventListener('click', homeClickedCallback);
      setHomeBtn(btn);
      (zoomBtn?.parentNode as any)?.insertBefore(btn, zoomBtn.nextSibling);
    }, [homeClickedCallback])

    const exportAsPng = () => {
      const container = document.getElementById('map-container');
      if (container) {
        setIsExporting('png');
        domtoimage
          .toBlob(container, {
            height: container.offsetHeight,
            width: container.offsetWidth,
          })
          .then(blob => {
            window.saveAs(blob, `export_${moment().format('YYYYMMDDHHmmss')}`);
            setIsExporting('');
          })
          .catch(() => setIsExporting(''));
      }
    };
    const exportAsPdf = async () => {
      const container = document.getElementById('map-container');
      if (container) {
        setIsHidenWhenExport(true);
        setIsExporting('pdf');
        try {
          const pages = []
          const containerUrl = await domtoimage
            .toPng(container, {
              height: container.offsetHeight,
              width: container.offsetWidth,
            })
          pages.push({ content: <Image src={containerUrl} />, title: `${t('map:header.export.carte.title')} - ${title}`.toLocaleUpperCase() })

          if ((hiddenLegendMapId === undefined || layer?.id !== hiddenLegendMapId) && legendData.data?.length > 0) {
            pages.push({
              content: ['analysis', 'method', 'knowMore'].map((type: string, key: number) => {
                const legend = legendDataByType[type]
                return legend && <View key={key} style={CoreCommon.PdfStyles.centerColumnItems}>
                  <Text style={{ marginBottom: '2vh' }}>{legend.legend}</Text>
                  <Image src={`/api/images/${legend.image.id}/media`} />
                </View >
              }).filter(_ => _ !== undefined),
              title: `${t('map:header.export.legend.title')} - ${title}`.toLocaleUpperCase()
            })
          }

          const doc = (
            <PdfDocument
              pages={pages}
              footer={<Text style={{ fontSize: 10 }}>{t('map:header.export.carte.source', { source: window.location.hostname })}</Text>}
            />
          );

          const asPdf = pdf([] as any);
          asPdf.updateContainer(doc);
          const blob = await asPdf.toBlob();
          saveAs(blob, `export_${moment().format('YYYYMMDDHHmmss')}.pdf`);
        } catch (error) {
          console.error(error)
        } finally {
          setIsExporting('');
          setIsHidenWhenExport(false);
        }
      }
    };

    useEffect(() => {
      const zoomBtnList = document.getElementsByClassName(
        'leaflet-control-zoom-in'
      );
      const homeButton = document.getElementsByClassName(leafletHomeButtonClass)[0];
      if (!isHidenWhenExport && map !== undefined && homeButton === undefined) {
        createHomeButton(zoomBtnList)
      }
    }, [isHidenWhenExport, createHomeButton, map])
    /*
     QGA - 04/01/2021
     To have a zoom more precise, add these options : 
          zoomSnap={0}
          zoomDelta={0.25}
      BUT the lib to generate png does not like zoom that are not integer...
      -> I tried to set the zoom to integer with Math.floor, 
      but I got strange behaviours (centered in an Africa, or in the ocean)
      --> checked lat/lng inversion, zoom round (that are good) and add a timeout, 
      but no one worked... 
    */

    return (
      <div className='flex-1 h-full relative' data-testid='map-canvas' id='map-canvas'>
        {isExporting && (
          <PageLoader message={t(`map:loading.export.${isExporting}`)} />
        )}
        <PoisContext.Consumer>
          {({ pois }) => (
            <Fragment>
              <PoisLinksContext.Consumer>
                {({ poisLinks }) => (
                  <MapContainer
                    id='map-container'
                    center={center}
                    zoom={zoom}
                    minZoom={mapConfig?.mapConfigMinZoom}
                    maxZoom={mapConfig?.mapConfigMaxZoom}
                    zoomControl={false}
                    whenCreated={onMapCreated}
                    className='h-full w-full'
                    maxBounds={
                      mapConfig?.mapConfigBoundaries
                        ? JSON.parse(
                          mapConfig?.mapConfigBoundaries
                        ).filter((o: any, index: number) =>
                          [0, 2].includes(index)
                        )
                        : []
                    }
                  >
                    {isHidenWhenExport ? null : (
                      <ZoomControl position='topright' />
                    )}
                    <TileLayer
                      url={layerUrl}
                      ref={layerRef}
                      attribution='MIA - © Openstreetmap France | Données <a href="http://www.openstreetmap.org/copyright">© les contributeurs OpenStreetMap</a> | Imagery © <a href="http://mapbox.com">Mapbox</a>'
                    />
                  </MapContainer>
                )}
              </PoisLinksContext.Consumer>
              <ConfirmModale
                id={markerDeleting?.id}
                message={t('map:marker.popup.other.delete.confirm', {
                  name: markerDeleting?.name,
                })}
                isOpened={typeof markerDeleting !== 'undefined'}
                handleClose={handlerMarkerDeletingConfirmClose}
              />
              {legendData.data?.length > 0 && (
                <MapLegend isHidenWhenExport={isHidenWhenExport} layer={layer} legendDataByType={legendDataByType} />
              )}
            </Fragment>
          )}
        </PoisContext.Consumer>
      </div>
    );
  }
);

MapCanvas.propTypes = {
  currentCenter: PropTypes.any,
  currentZoom: PropTypes.number,
  isCreatingPoiOnMap: PropTypes.string,
  layer: PropTypes.any,
  onHomeClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};

MapCanvas.defaultProps = {
  isCreatingPoiOnMap: undefined,
};

export default MapCanvas;
