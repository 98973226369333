// React libs
import React, { FC, useState, Fragment, useMemo } from 'react';
import { Field, Form, FormikProps, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
// Components
import Button from '../../../../../../Core/Components/UiKit/Button/Button';
import TextFieldMia from '../../../../../../Core/Components/UiKit/Form/TextField/TextField';
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
// Type
import * as Types from './PasswordProfilForm.type';
// Utils
import {
  getFormPropTypes, isDisabled,
} from '../../../../../../Core/Utils/FormUtils';
import Autocomplete from '../../../../../../Core/Components/UiKit/Form/Autocomplete/Autocomplete';


const UserPasswordForm: FC<Types.IProps> = ({
  defaultValues,
  miscData,
  miscFunctions,
  onFormSubmit,
  validationSchema,
}) => (
  <Formik
    initialValues={defaultValues}
    onSubmit={onFormSubmit}
    validationSchema={validationSchema}
    enableReinitialize
  >
    {(formikProps: FormikProps<any>) => (
      <UserPasswordFormikForm
        {...formikProps}
        miscFunctions={miscFunctions}
        miscData={miscData}
      />
    )}

  </Formik>
);

UserPasswordForm.propTypes = getFormPropTypes();

export default UserPasswordForm;


const UserPasswordFormikForm = ({
  errors,
  isSubmitting,
  touched,
  miscData,
  setFieldValue,
  setFieldTouched
}: FormikProps<any> & any) => {
  const { t } = useTranslation(['common', 'user']);
  const [edit, setEdit] = useState<any>();
  const fieldContainerClasses = 'flex w-full items-center mx-2';
  const fieldContainerFieldClasses = `${fieldContainerClasses} flex-1`;
  const options = useMemo(() => miscData?.map((acces:any) =>({
    label:acces.label,
    value:acces.id
    })) ?? [], [miscData])

  // Handlers
  const handleReset = ()=>{
    setTimeout(() => {
      setEdit(false)
      }, 100);
    
  }
  return (
    <>
      <div className="flex w-full">
        <div className="mt-3 mb-4 ml-4 items-center">
          <Typography variant="h5" gutterBottom>
            Compte
          </Typography>
        </div>
        <div className="mt-2">
          <Button 
          onClick={() => setEdit(true)} 
          variant={"outlined"}
          startIcon={
            <Fragment>
              {edit && <span>...</span> }
              <FaIcon name='pencil' />
            </Fragment>
                  } 
          className="mb-4 ml-4"
          disabled={edit ? true : false}
          >
            {edit ? t("user:En cours d'édition") : t('user:Éditer') }
          </Button>
        </div>
      </div>
      <Divider variant="middle" />
      <Form data-testid='form-password-profils' className='flex w-full mt-2 ml-2'>
        <div className='flex flex-col w-11/12'>
          <div className={`${fieldContainerFieldClasses}`}>
          <Field
              id='accesType'
              name='accesType'
              component={Autocomplete}
              options={options}
              label={t("user:Type d'accès")}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={`${fieldContainerFieldClasses}`}>
            <Field
              id='account'
              name='account'
              label={t('user:Identifiant')}
              component={TextFieldMia}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={`${fieldContainerFieldClasses}`}>
            <Field
              id='oldPassword'
              name='oldPassword'
              type='password'
              component={TextFieldMia}
              canDisplayPassword
              label={t('user:Ancient mot de passe')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={`${fieldContainerFieldClasses}`}>
            <Field
              id='newPassword'
              name='newPassword'
              type='password'
              component={TextFieldMia}
              canDisplayPassword
              label={t('user:Nouveau mot de passe')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={`${fieldContainerFieldClasses}`}>
            <Field
              id='confirmPassword'
              name='confirmPassword'
              type='password'
              component={TextFieldMia}
              canDisplayPassword
              label={t('user:Confirmation nouveau mot de passe')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
            />
          </div>
          <div className={`${fieldContainerFieldClasses}`}>
            <Field
              id='comment'
              name='comment'
              component={TextFieldMia}
              label={t('user:Commentaire')}
              color='secondary'
              className='flex-1'
              disabled={edit ? false : true}
              multiline
            />
          </div>
          {edit &&
          <div className={`${fieldContainerClasses} justify-center`} >
                <Button
                  type='reset'
                  variant='text'
                  size='large'
                  className='mx-2'
                  onClick={handleReset}
                >
                  {t("ANNULER")}
                </Button>
                <Button
                  type='submit'
                  variant='text'
                  size='large'
                  className='mx-2'
                  onClick={handleReset}
                  disabled={isDisabled(errors, isSubmitting, touched)}
                  startIcon={
                    isSubmitting ? <FaIcon name='spinner' className='fa-spin' /> : null
                  }
                >
                  {t("ENREGISTRER")}
                </Button>
              </div>
            }
        </div>
      </Form>
    </>
  );
};