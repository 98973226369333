// React libs
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
// Components
import FaIcon from '../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
// Type
import * as Types from './AppSidebar.type';
// Common
import Common from '../../../Resources/Common';

const AppSidebar: FC<Types.IProps> = () => {
  // Variables
  const iconClasses = 'fill-current text-2xl';
  const menuConf = [
    {
      id: 'map',
      icon: 'map-o',
      link: Common.Routes.routeMap,
    },
    {
      id: 'bryntum',
      icon: 'rocket',
      link: Common.Routes.routeBryntumTest,
    },
    {
      id: 'contact',
      icon: 'address-book-o',
      link: Common.Routes.routeProfile,
      // linkActive: Common.Routes.routContact
    },
  ];

  // Getters
  const isActive = (link: string) => {
    const pathname = document.location.pathname;
    const paths = pathname.split('/');
    const linkElements = link.split('/');
    const linkToTest =
      linkElements[0] === '' ? linkElements[1] : linkElements[0];
    let isActive = false;
    if (paths.length > 1) {
      isActive = paths[1] === linkToTest;
    }
    return isActive;
  };

  return (
    <div
      className='bg-main-light flex flex-col px-2 py-1 w-16'
      data-testid='app-sidebar'
    >
      {menuConf.map((m: any) => (
        <Link
          to={`/${m.link}`}
          key={m.id}
          className={`flex items-center justify-center py-2 my-1 cursor-pointer rounded ${isActive(m.link) ? 'bg-active' : 'hover:bg-active-hover'
            } `}
        >
          <FaIcon
            name={m.icon}
            className={`${iconClasses} ${isActive(m.link) ? 'text-white' : ''}`}
          />
        </Link>
      ))}
    </div>
  );
};

export default AppSidebar;
