// React libs
import React, { FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
// Components
import LayerForm from '../../Form/LayerForm/LayerForm';
import Typography from '../../../../../Core/Components/UiKit/Typography/Typography';
// Services
import CommonService from '../../../../../Core/Data/Services/CommonService';
import MapService from '../../../Data/Services/MapService';
// Type
import * as Types from './LayerEdition.type';
import * as FormTypes from '../../Form/LayerForm/LayerForm.type';
import * as CoreTypes from '../../../../../Core/Data/Models/Core.type';
import * as MapTypes from '../../../Data/Models/Map.type';

const LayerEdition: FC<Types.IProps> = ({ layer, onEditDone }) => {
  // Variables
  const { t } = useTranslation(['admin']);
  const { enqueueSnackbar } = useSnackbar();
  interface ILegendPart {
    label: string;
    key: MapTypes.TLegendType;
  }
  const analyzePart: ILegendPart = {
    label: t('admin:layers.form.legend.analyze.title'),
    key: 'analysis',
  };
  const methodPart: ILegendPart = {
    label: t('admin:layers.form.legend.method.title'),
    key: 'method',
  };
  const knowMorePart: ILegendPart = {
    label: t('admin:layers.form.legend.knowMore.title'),
    key: 'knowMore',
  };

  // State
  const [legendImages, setLegendImages]: [
    { [key: string]: string[] },
    Function
  ] = useState<{ [key: string]: string[] }>({});

  // Effects
  useEffect(() => {
    const legendImagesTemp: { [key: string]: string[] } = {
      [analyzePart.key]: [],
      [methodPart.key]: [],
      [knowMorePart.key]: [],
      fklayerMapId: [''],
    };
    // Set correct image URl to the correct variable
    if (layer?.images) {
      layer.images.forEach((i: any, index: number) => {
        if (legendImagesTemp.hasOwnProperty(i.legendType)) {
          legendImagesTemp[i.legendType].push(
            `/api/images/${i.image.id}/media`
          );
        }
      });
      // Set Foreign key for Helping delete later on
      legendImagesTemp.fklayerMapId = [layer.id];
    }
    setLegendImages(legendImagesTemp);
  }, [layer]);

  // Handlers
  const onDeleteImage = async (
    urls: string[],
    key: string,
    fklayerMapId: string
  ) => {
    const imagesToDelete = urls.map((url: string) => {
      const elements = url.split('/');
      return elements[elements.length - 2];
    });
    for (let i = 0; i < imagesToDelete.length; i++) {
      await CommonService.deleteImageLegend(fklayerMapId, key);
      await CommonService.deleteImage(imagesToDelete[i]);
    }
    const images = { ...legendImages };
    images[key] = [];
    setLegendImages(images);
  };
  const handleLegend = async (
    values: FormTypes.IFormValues,
    fklayerMap: string
  ) => {
    const legendPart = [analyzePart, methodPart, knowMorePart];
    for (let i = 0; i < legendPart.length; i++) {
      const imageValue =
        values[
        `legend${legendPart[i].key}Image` as keyof FormTypes.IFormValues
        ];
      if (imageValue !== null) {
        // Upload image
        const image: CoreTypes.IImageUploadData = await CommonService.uploadImage(
          {
            file: imageValue,
          }
        );
        // Add image to layer
        await MapService.createLegendImage({
          fklayerMap,
          legendType: legendPart[i].key,
          legend:
            values[
            `legend${legendPart[i].key}Label` as keyof FormTypes.IFormValues
            ],

          fkimage: image.data.id,
        });
      } else {
      }
    }
  };
  const updateLayer = async (
    values: FormTypes.IFormValues,
    setSubmitting: (...args: any) => any
  ) => {
    try {
      setSubmitting(true);
      // Create layer
      await MapService.updateLayer(layer!.id, values);
      // Add legends
      await handleLegend(values, layer!.id);
      setSubmitting(false);
      onEditDone();
    } catch (e) {
      enqueueSnackbar(e?.error?.message || t('common:errors.defaultMessage'), {
        variant: 'error',
      });
      setSubmitting(false);
    }
  };
  const createLayer = async (
    values: FormTypes.IFormValues,
    setSubmitting: (...args: any) => any
  ) => {
    try {
      setSubmitting(true);
      // Create layer
      const layer: MapTypes.ILayerCreatedData = await MapService.createLayer(
        values
      );
      // Add legends
      await handleLegend(values, layer.data.id);
      setSubmitting(false);
      onEditDone();
    } catch (e) {
      enqueueSnackbar(e?.error?.message || t('common:errors.defaultMessage'), {
        variant: 'error',
      });
      setSubmitting(false);
    }
  };

  // Renders
  const renderForm = () => {
    const defaultValues: FormTypes.IFormValues = {
      default: layer ? layer.default : false,
      name: layer ? layer.name : '',
      thematic: layer?.thematic ?? '',
      subThematic: layer?.subThematic ?? '',
      order: layer ? layer.order : 0,
      thumbnailUrl: layer ? layer.thumbnailUrl : '',
      url: layer ? layer.url : '',
      legendanalysisLabel:
        layer?.images.find(
          (i: MapTypes.ILayerMapImage) => i.legendType === 'analysis'
        )?.legend || '',
      legendanalysisImage: null,
      legendmethodLabel:
        layer?.images.find(
          (i: MapTypes.ILayerMapImage) => i.legendType === 'method'
        )?.legend || '',
      legendmethodImage: null,
      legendknowMoreLabel:
        layer?.images.find(
          (i: MapTypes.ILayerMapImage) => i.legendType === 'knowMore'
        )?.legend || '',
      legendknowMoreImage: null,
    };
    const validationSchema = Yup.object({
      name: Yup.string().required(
        t('admin:layers.form.overall.name.errors.missing')
      ),
      thematic: Yup.string().when('subThematic', {
        is: value => value != null && value !== '',
        then: Yup.string().required(
          t('admin:layers.form.overall.thematic.errors.missing')
        )
      }),
      subThematic: Yup.string(),
      url: Yup.string().required(
        t('admin:layers.form.tile.url.errors.missing')
      ),
      order: Yup.number(),
    });
    const handleSubmit = (
      values: FormTypes.IFormValues,
      { setSubmitting }: FormikHelpers<FormTypes.IFormValues>
    ) => {
      setSubmitting(true);
      if (layer) {
        updateLayer(values, setSubmitting);
      } else {
        createLayer(values, setSubmitting);
      }
    };
    return (
      <div>
        <Typography variant='h4' className='mb-4 truncate w-full'>
          {layer
            ? `${t('admin:layers.form.title.update')} ${layer.name}`
            : t('admin:layers.form.title.create')}
        </Typography>
        <LayerForm
          defaultValues={defaultValues}
          onFormSubmit={handleSubmit}
          validationSchema={validationSchema}
          miscFunctions={{ onEditDone, onDeleteImage }}
          miscData={{ legendImages }}
        />
      </div>
    );
  };

  return (
    <div
      className='h-full overflow-auto p-4 w-full'
      data-testid='admin-layer-edition'
    >
      {renderForm()}
    </div>
  );
};

LayerEdition.propTypes = {
  layer: PropTypes.any,
};

export default LayerEdition;
