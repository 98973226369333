// React libs
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Collapse from '@material-ui/core/Collapse';
import PropTypes from 'prop-types';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { sortedIndex } from 'lodash'
import { useTranslation } from 'react-i18next';
// Components
import FaIcon from '../../../../../../Core/Components/UiKit/Icon/FaIcon/FaIcon';
import LayerPreview from './LayerPreview/LayerPreview';
import LocalLoader from '../../../../../../Core/Components/UiKit/Loader/LocalLoader/LocalLoader';
import MapSearchBar from './MapSearchBar/MapSearchBar';
import Typography from '../../../../../../Core/Components/UiKit/Typography/Typography';
// Hooks
import useMapLayers, { IGetLayersHook } from '../../../../Data/Hooks/MapLayers';
// Type
import * as Types from './MapSidebarCard.type';
import * as MapTypes from '../../../../Data/Models/Map.type';

const StyledThreeItem: FC<{ title: string, nodeId: string } & React.ComponentProps<'div'>> = ({ children, title, nodeId }) => {
  const isSubThematic = useMemo(() => nodeId.startsWith('subThematic-'), [nodeId])
  return <TreeItem
    nodeId={nodeId}
    TransitionComponent={Collapse}
    label={<Typography variant={isSubThematic ? 'h6' : 'h5'} className='text-selection-inverse font-extrabold'>
      {title}
    </Typography>}>
    {children}
  </TreeItem>
}

const MapSidebarCard: FC<Types.IProps> = ({ activeLayer, onLayerClick }) => {
  // state
  const [layersFilter, setLayersFilter] = useState<any>()
  const [expanded, setExpanded] = useState<string[]>([])

  // Variables
  const { t } = useTranslation(['map']);

  // Hooks
  const layers: IGetLayersHook = useMapLayers(true, layersFilter);

  // Misc
  if (layers && layers.data)
    layers.data.data.sort((a: MapTypes.ILayer, b: MapTypes.ILayer) => {
      if (a.order < b.order) return -1;
      else if (a.order > b.order) return 1;
      else if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      else if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      else return 0;
    });

  interface IGroupedLayers {
    layersWithoutThematic: MapTypes.ILayer[]
    thematicTitles: string[]
    thematic: {
      [title: string]: {
        layersWithoutSubThematic: MapTypes.ILayer[]
        subThematicTitles: string[]
        subThematic: { [title: string]: MapTypes.ILayer[] }
      }
    }
  }

  const groupedLayers: IGroupedLayers = useMemo(() => {
    const result: IGroupedLayers = { layersWithoutThematic: [], thematicTitles: [], thematic: {} }
    if (layers.data == null) {
      return result
    }

    layers.data.data.forEach((layer: MapTypes.ILayer) => {
      // Thematic
      if (layer.thematic == null) {
        return result.layersWithoutThematic.push(layer)
      }

      let thematic = result.thematic[layer.thematic]
      if (thematic === undefined) {
        thematic = result.thematic[layer.thematic] = {
          layersWithoutSubThematic: [],
          subThematicTitles: [],
          subThematic: {}
        }

        // add a new title to the sorted array
        result.thematicTitles.splice(sortedIndex(result.thematicTitles, layer.thematic), 0, layer.thematic)
      }

      // Sub-thematic
      if (layer.subThematic == null) {
        return thematic.layersWithoutSubThematic.push(layer)
      }

      let subThematic = thematic.subThematic[layer.subThematic]
      if (subThematic === undefined) {
        subThematic = thematic.subThematic[layer.subThematic] = []

        // add a new title to the sorted array
        thematic.subThematicTitles.splice(sortedIndex(thematic.subThematicTitles, layer.subThematic), 0, layer.subThematic)
      }

      subThematic.push(layer)
    })

    return result
  }, [layers.data])

  // Handlers
  const handleToggle = useCallback((event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }, [])

  // Actions
  const expandAll = useCallback(() => {
    const nodeIds = [...groupedLayers.thematicTitles.map(title => `thematic-${title}`)]
    Object.values(groupedLayers.thematic).forEach(({ subThematicTitles }) => {
      nodeIds.push(...subThematicTitles.map(title => `subThematic-${title}`))
    })
    setExpanded(nodeIds)
  }, [groupedLayers])
  const collapseAll = useCallback(() => {
    setExpanded([])
  }, [])

  // Effects
  useEffect(() => {
    if (layersFilter === undefined) {
      collapseAll()
    } else {
      expandAll()
    }
  }, [collapseAll, expandAll, layersFilter])

  return (
    <div className='h-full p-0 sm:p-2' data-testid='map-sidebar-card'>
      <MapSearchBar onFilterChange={setLayersFilter} />
      {layers.isLoading ? (
        <div className='flex h-full items-center justify-center w-full'>
          <LocalLoader message={t('map:sidebar.cards.loading')} />
        </div>
      ) : (<div>
        <div>
          <TreeView
            onNodeToggle={handleToggle}
            expanded={expanded}
            defaultCollapseIcon={
              <FaIcon name='caret-down' className='text-3xl text-selection-inverse' />
            }
            defaultExpandIcon={<FaIcon name='caret-right' className='text-3xl text-selection-inverse' />}
          >
            {groupedLayers.thematicTitles.map(title => {
              const thematic = groupedLayers.thematic[title]
              return <StyledThreeItem nodeId={`thematic-${title}`} title={title} key={title}>
                {thematic.subThematicTitles.map(title => {
                  const subThematic = thematic.subThematic[title]
                  return <StyledThreeItem nodeId={`subThematic-${title}`} title={title} key={title}>
                    {subThematic.map(layer => <LayerPreview
                      active={activeLayer?.id === layer.id}
                      key={layer.id}
                      layer={layer}
                      onLayerClick={onLayerClick}
                    />)}
                  </StyledThreeItem>
                })}
                {thematic.layersWithoutSubThematic.map(layer => <LayerPreview
                  active={activeLayer?.id === layer.id}
                  key={layer.id}
                  layer={layer}
                  onLayerClick={onLayerClick}
                />)}
              </StyledThreeItem>
            })}
          </TreeView>
          {groupedLayers.layersWithoutThematic.map((layer: MapTypes.ILayer) => (
            <LayerPreview
              active={activeLayer?.id === layer.id}
              key={layer.id}
              layer={layer}
              onLayerClick={onLayerClick}
            />
          ))}
        </div>
      </div>
      )}
    </div>
  );
};

MapSidebarCard.propTypes = {
  activeLayer: PropTypes.any,
  onLayerClick: PropTypes.func.isRequired,
};

export default MapSidebarCard;
