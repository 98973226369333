// React libs
import React, { FC } from 'react';
// Components
import Button from '../../Button/Button';
import FieldError from '../../Error/FieldError/FieldError';
// Types
import * as Types from './FileUpload.type';

const FileUpload: FC<Types.IProps> = ({
  field,
  form: { errors, setFieldValue, touched },
  deleteLabel,
  label,
  multiple,
  defaultFiles,
  onDeleteFile,
}) => {
  // Variables
  const customField = {
    ...field,
    value: '',
    onChange: (e: any) => {
      const values = e?.target?.files;
      if (multiple) {
        setFieldValue(
          field.name,
          Object.keys(values).map((k: string) => values[k])
        );
      } else if (values.length > 0) {
        setFieldValue(field.name, values[0]);
      }
    },
  };

  // Getters
  const getFieldValues = () => {
    let values = [];

    if (field.value) {
      if (multiple) {
        values = field.value.map((f: File) => URL.createObjectURL(f));
      } else {
        values.push(URL.createObjectURL(field.value));
      }
    } else if (defaultFiles) {
      values = [...defaultFiles];
    }
    return values;
  };

  // Handlers
  const onBtnClick = (e: any) => {
    if ((defaultFiles || []).length > 0) {
      onDeleteFile(defaultFiles || []);
      e.preventDefault();
    }
  };

  return (
    <div className='flex items-center' data-testid='file-upload'>
      {getFieldValues().map((f: string, index: number) => (
        <img key={index} src={f} alt='preview' className='max-h-24 mr-2' />
      ))}
      <div>
        <input
          accept='image/*'
          className='hidden'
          multiple={multiple}
          type='file'
          id={field.name}
          {...customField}
        />
        <label htmlFor={field.name}>
          <Button
            variant='contained'
            color='secondary'
            component='span'
            onClick={onBtnClick}
          >
            {(defaultFiles || []).length > 0 ? deleteLabel : label}
          </Button>
        </label>
        {errors[field.name] && touched[field.name] && (
          <FieldError
            errors={errors[field.name]}
            touched={touched[field.name]}
          />
        )}
      </div>
    </div>
  );
};

FileUpload.defaultProps = {
  defaultFiles: [],
  multiple: false,
};

export default FileUpload;
