// React libs
import React, { FC, useCallback } from 'react';
import get from 'lodash/get'
import Radio, { RadioProps } from '@material-ui/core/Radio'
// Components
import FieldError from '../../Error/FieldError/FieldError';
// Type
import * as Types from './ThreeColorsRadio.type';
// Utils
import { hasErrors } from '../../../../Utils/FormUtils'

interface IColorRadio extends RadioProps {
  selectedValue: string
}

export const ColorRadioByValue = {
  0: ({ onChange, selectedValue, ...props }: IColorRadio) => <Radio
    checked={selectedValue === '0'}
    onChange={onChange}
    value='0'
    classes={{
      root: 'text-green-400',
      checked: 'text-green-600'
    }}
    {...props}
  />,
  1: ({ onChange, selectedValue, ...props }: IColorRadio) => <Radio
    checked={selectedValue === '1'}
    onChange={onChange}
    value='1'
    classes={{
      root: 'text-yellow-400',
      checked: 'text-yellow-600'
    }}
    {...props}
  />,
  2: ({ onChange, selectedValue, ...props }: IColorRadio) => <Radio
    checked={selectedValue === '2'}
    onChange={onChange}
    value='2'
    classes={{
      root: 'text-red-400',
      checked: 'text-red-600'
    }}
    {...props}
  />,
}

const ThreeColorsRadio: FC<Types.IProps> = ({ form, field, ...props }) => {
  // Handlers
  const onChange = useCallback(e => {
    form.setFieldTouched(field.name, true)
    form.setFieldValue(field.name, e.target.value)
  }, [form, field.name])

  return <div className='w-full' data-testid='Threecolorsradio'>
    <div>
      {Object.values(ColorRadioByValue).map((Component, key) => <Component key={key} onChange={onChange} selectedValue={field.value} {...props} />)}
    </div>

    {hasErrors(form) && (
      <FieldError
        color='secondary'
        errors={get(form.errors, field.name)}
        touched={get(form.touched, field.name)}
      />
    )}
  </div>
}

export default ThreeColorsRadio