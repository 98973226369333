// React libs
import React, { FC } from 'react';
import CheckboxComponent from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import get from 'lodash/get'
// Components
import FieldError from '../../Error/FieldError/FieldError';
// Type
import * as Types from './Checkbox.type';
// Utils
import { hasErrors } from '../../../../Utils/FormUtils'


const Checkbox: FC<Types.IProps> = ({
  field,
  form,
  label,
  labelClasses,
  labelControlled,
  onClick,
  ...props
}) => {
  const { errors, touched } = form;

  const customClasses = {
    root: '',
    colorPrimary: 'text-selection',
    colorSecondary: 'text-white',
  };

  const customField = {
    ...field,
    onClick: (e: any) => {
      if (onClick) {
        onClick(e);
      }
      form.setFieldValue(field.name, !(e.target.value === 'true'));
    },
  };

  // Getters
  const getCheckbox = (className: string = '') => {
    const componentClasses = { ...customClasses };
    componentClasses.root = `${customClasses.root} ${className}`;
    return (
      <CheckboxComponent
        checked={field.value}
        classes={componentClasses}
        {...customField}
        {...props}
      />
    );
  };
  return (
    <div className='w-full' data-testid='checkbox'>
      {labelControlled ? (
        <FormControlLabel
          control={getCheckbox()}
          className={labelClasses}
          label={label}
        />
      ) : (
        <div className='flex items-center'>
          {getCheckbox()}
          <div>{label}</div>
        </div>
      )}
      {hasErrors(form) && (
        <FieldError
          color={
            props.color && ['primary', 'secondary'].includes(props.color)
              ? (props.color as 'primary' | 'secondary')
              : 'secondary'
          }
          errors={get(errors, field.name)}
          touched={get(touched, field.name)}
        />
      )}
    </div>
  );
};

Checkbox.defaultProps = {
  labelControlled: true,
};

export default Checkbox;
