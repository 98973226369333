// React libs
import React, { FC } from 'react';
// Layout
import AppLayout from '../../../../App/Components/Layout/AppLayout/AppLayout';
// Components
import Gantt, { Column } from '../../Components/Bryntum/Gantt/Gantt';
// Type
import * as Types from './BryntumScene.type';

const BryntumScene: FC<Types.IProps> = () => {
  // Handlers
  const handleTaskSelect = (record: any) => {
    console.log('RECORD', record);
  };

  return (
    <AppLayout
      headerConf={{
        title: {
          label: 'Test',
          icon: 'rocket',
          subLabel: 'Bryntum',
        },
      }}
    >
      <div
        data-testid='bryntum-test-page'
        className='flex flex-col h-full w-full'
      >
        <Gantt
          // props used to configure the gantt chart
          title='Test diagramme de Gant'
          containerId='gantt1'
          taskUrl={`${process.env.PUBLIC_URL}/data/tasks.json`}
          dependenciesUrl={`${process.env.PUBLIC_URL}/data/dependencies.json`}
          preset='weekAndDayLetter'
          // triggered on task select
          onTaskSelect={handleTaskSelect}
        >
          {/* columns as children */}
          <Column xtype='namecolumn' width={150} />
          <Column xtype='startdatecolumn' width={90} />
          <Column xtype='enddatecolumn' width={90} />
        </Gantt>
      </div>
    </AppLayout>
  );
};

export default BryntumScene;
