interface IData {
  color: string;
  name: string;
  value: number;
}
export interface IDonutProps {
  colors?: string[];
  data: IData[];
  el?: any;
  position: 'left' | 'center' | 'right';
  size: number;
  text: {
    text: string;
    align: string;
  };
  weight: number;
}
export const createClusterDonut = ({
  colors,
  data,
  el,
  position,
  size,
  text,
  weight,
}: IDonutProps) => {
  const div = document.createElement('div');
  const r = size / 2;
  const setAttribute = (el: any, o: any) => {
    for (const j in o) {
      el.setAttribute(j, o[j]);
    }
  };
  const sum = data.reduce((sum: number, d: IData) => sum + d.value, 0);
  const NS = 'http://www.w3.org/2000/svg';
  const svg = document.createElementNS(NS, 'svg');
  const arcRadius = r - weight / 2;
  let startAngle = -Math.PI / 2;

  div.className = 'donut';
  div.style.width = div.style.height = size + 'px';
  if (position === 'center') {
    div.style.position = 'relative';
    div.style.top = -size / 2 + 'px';
    div.style.left = -size / 2 + 'px';
  }

  if (text) {
    let span = document.createElement('span');
    span.className = 'text';
    span.innerHTML = text.text;

    span.style.position = 'relative';
    span.style.zIndex = '1600';

    if (text.align === 'center') {
      span.style.display = 'inline-block';
      span.style.lineHeight = '100%';
      span.style.verticalAlign = 'middle';
      div.style.lineHeight = div.style.height;
      div.style.textAlign = 'center';
    }

    div.appendChild(span);
  }

  svg.setAttribute('height', size + 'px');
  svg.setAttribute('width', size + 'px');

  div.appendChild(svg);

  data.forEach((d: IData, index: number) => {
    let value = d.value / sum;
    value = value === 1 ? 0.9999 : value;
    const arc = document.createElementNS(NS, 'path');

    var segmentAngle = value * Math.PI * 2,
      endAngle = segmentAngle + startAngle,
      largeArc = (endAngle - startAngle) % (Math.PI * 2) > Math.PI ? 1 : 0,
      startX = r + Math.cos(startAngle) * arcRadius,
      startY = r + Math.sin(startAngle) * arcRadius,
      endX = r + Math.cos(endAngle) * arcRadius,
      endY = r + Math.sin(endAngle) * arcRadius;

    startAngle = endAngle;

    setAttribute(arc, {
      d: [
        'M',
        startX,
        startY,
        'A',
        arcRadius,
        arcRadius,
        0,
        largeArc,
        1,
        endX,
        endY,
      ].join(' '),
      stroke: d.color || (colors ? colors[index % colors.length] : '#ddd'),
      'stroke-width': weight,
      fill: 'none',
      'data-name': d.name,
      class: 'donut-arc',
    });

    svg.appendChild(arc);
  });

  // end
  if (el) {
    el.appendChild(div);
  }

  return div;
};
