import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types';

export const getFormPropTypes = () => ({
  defaultValues: PropTypes.any.isRequired,
  miscData: PropTypes.any,
  miscFunctions: PropTypes.any,
  onFormSubmit: PropTypes.func.isRequired,
  validationSchema: PropTypes.any,
});

export const isDisabled = (
  errors: { [key: string]: boolean },
  isSubmitting: boolean,
  touched?: { [key: string]: boolean }
) => {
  let hasBeenTouched = touched ? Object.keys(touched).length > 0 : true;
  let hasError = hasBeenTouched && Object.keys(errors).length > 0;
  return isSubmitting || !hasBeenTouched || hasError;
};

export const hasErrors = (form: any) => !isEmpty(form.errors) && !isEmpty(form.touched)

export type IOption = {
  label: string
  value: any
}
