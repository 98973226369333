// React libs
import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
// Scenes
import ErrorScene from '../../Pages/Error/ErrorScene';
import ForgotPasswordScene from '../../Pages/Auth/ForgotPassword/ForgotPasswordScene';
import LoginScene from '../../Pages/Auth/Login/LoginScene';
import ResetPasswordScene from '../../Pages/Auth/ResetPassword/ResetPasswordScene';
import BryntumScene from '../../../Dimensions/Test/Pages/Bryntum/BryntumScene';
import LayerManagerScene from '../../Pages/Admin/LayerManager/LayerManagerScene';
import MapScene from '../../../Dimensions/Map/Pages/MapScene';
import UserProfil from '../../Pages/Admin/Profil/ProfilUser'
import AddressProfil from '../../Pages/Admin/Address/Address'
// Services
import LocalStorage from '../../../Core/Data/Services/Storage/LocalStorage';
// Common
import Common from '../../Resources/Common';

const defaultRoute = Common.Routes.routeMap;
const defaultAdminRoute = Common.Routes.routeLayerManager;


const PrivateRoute = ({ component, ...rest }: any) => (
  <Route
    {...rest}
    render={props => {
      const userId = LocalStorage.get(LocalStorage.keys.userId);
      return userId ? (
        React.createElement(component, props)
      ) : (
        <Redirect
          to={{
            pathname: `/${Common.Routes.routeLogin}`,
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

const PublicRoute = ({ component, params, ...rest }: any) => (
  <Route
    {...rest}
    render={(props: any) =>
      React.createElement(component, { ...props, ...params })
    }
  />
);

const NotFoundRoute = () => (
  <Redirect
    to={{
      pathname: `/${Common.Routes.routeError}/404`,
    }}
  />
);

const RouterComponent: FC = () => (
  <main>
    <Switch>
      {/* LOGIN */}
      <PublicRoute
        exact
        path={`/${Common.Routes.routeLogin}`}
        component={LoginScene}
      />
      {/* FORGOT PASSWORD */}
      <PublicRoute
        exact
        path={`/${Common.Routes.routeForgotPassword}`}
        component={ForgotPasswordScene}
      />
      {/* RESET PASSWORD */}
      <PublicRoute
        path={`/${Common.Routes.routeResetPassword}/:encryptedString`}
        component={ResetPasswordScene}
      />
      {/* HOME */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeHome}`}
        component={(props: any) => (
          <Redirect
            to={{
              pathname: `${defaultRoute}`,
              state: { from: props.location },
            }}
          />
        )}
      />
      {/* APP */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeMap}`}
        component={MapScene}
      />
      {/* ADMIN */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeAdmin}`}
        component={(props: any) => (
          <Redirect
            to={{
              pathname: `${defaultAdminRoute}`,
              state: { from: props.location },
            }}
          />
        )}
      />
      {/* PROFIL USER */}
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeProfile}`}
        component={UserProfil}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeAddress}`}
        component={AddressProfil}
      />
      <PrivateRoute
        exact
        path={`/${Common.Routes.routeLayerManager}`}
        component={LayerManagerScene}
      />
      {/* ERRORS */}
      <PublicRoute
        path={`/${Common.Routes.routeError}/:code`}
        component={ErrorScene}
      />
      {/* TESTS */}
      <PublicRoute
        exact
        path={`/${Common.Routes.routeBryntumTest}`}
        component={BryntumScene}
      />
      <NotFoundRoute />
    </Switch>
  </main>
);

export default RouterComponent;
