/**
 * All networking url used in this app
 */
export default class CommonUrls {
  static path = process.env.REACT_APP_HOST;

  static get apiPath(): string {
    return `${CommonUrls.path || ''}/api`;
  }

  /**
   * LOGIN
   */
  static getLogin() {
    return `${CommonUrls.apiPath}/auth/login`;
  }
  static getForgotPassword() {
    return `${CommonUrls.apiPath}/lostpasswords/reset_ask`;
  }
  static getResetPassword(encrytedString: string) {
    return `${CommonUrls.apiPath}/lostpasswords/reset_update/${encrytedString}`;
  }
  static getLogout() {
    return `${CommonUrls.apiPath}/auth/logout`;
  }
  /**
   * PROFIL
   */
  static getUser(id: string) {
    return `${CommonUrls.apiPath}/persons/${id}?expand=all`;
  }
  static setUser(id: string) {
    return `${CommonUrls.apiPath}/persons/${id}`;
  }
  static getAddress() {
    return `${CommonUrls.apiPath}/addresses`;
  }
  static getAddressWhitId(id: string) {
    return `${CommonUrls.apiPath}/addresses/${id}`;
  }
  static getAccount(id: string) {
    return `${CommonUrls.apiPath}/accounts/${id}`;
  }
  static getAccessTypes() {
    return `${CommonUrls.apiPath}/accesstypes`;
  }
  static getProfil(id: string) {
    return `${CommonUrls.apiPath}/profiles/${id}`;
  }
  /**
   * IMAGES
   */
  static uploadImage() {
    return `${CommonUrls.apiPath}/images/upload`;
  }
  static deleteImage(id: string) {
    return `${CommonUrls.apiPath}/images/${id}`;
  }
  static deleteLayerMapImageLegend(fklayerMapId: string, legendtype: string) {
    return `${CommonUrls.apiPath}/layermapimages/${fklayerMapId}/legend/${legendtype}`;
  }

  // DATA
  static getPoiTypes() {
    return `${CommonUrls.apiPath}/poitypes?expand=all`;
  }
  static getThematic() {
    return `${CommonUrls.apiPath}/thematic`
  }
  static getTerritories() {
    return `${CommonUrls.apiPath}/territories`
  }
}
