// React libs
import React, { FC } from 'react';
import UserContext from '../../../../Core/Data/Contexts/UserContext';
import { isAdmin, isWriter } from '../../../../Core/Utils/User';
// Layout
import BaseLayout from '../../../Components/Layout/BaseLayout/BaseLayout';
import AppSidebar from '../../../Components/Sidebar/AppSidebar/AppSidebar';
// Types
import * as Types from './Address.type'
//bryntum
import Gantt, { Column } from '../../../../Dimensions/Test/Components/Bryntum/Gantt/Gantt';

const Address: FC<Types.IProps> = () => {
  return <div data-testid='address'>
    <BaseLayout headerConf={{
      title: {
        label: 'address',
        icon: 'address-card-o',
      },
    }}>
      <UserContext.Consumer>
        {({ user }) => (
          <div className='flex flex-1 overflow-hidden w-full'>
            {user && (isAdmin(user) || isWriter(user)) && <AppSidebar />}
            <div
              className={`flex-1 relative`}
              data-testid='app-layout'
            >
              <Column xtype='namecolumn' width={150} />
              <Column xtype='startdatecolumn' width={90} />
              <Column xtype='enddatecolumn' width={90} />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </BaseLayout>
  </div>
}

export default Address