// React libs
import L from 'leaflet';

interface IMarkersLinkProps {
  originMarker: any;
  destMarker: any;
  map: any;
  clusters: any;
  options: any;
}

export class KLink extends L.Path {
  options = {
    fill: false,
    opacity: 1,
    weight: 2,
  };

  _circleMargin: number = 0;
  _clusters: any = {};
  _icon1: any;
  _icon2: any;
  _map: any;
  _marker1latlng: [number, number] = [0, 0];
  _marker2latlng: [number, number] = [0, 0];
  _options: any;
  _path: any = document.createElement('path');
  _point1: any;
  _point2: any;
  _r1: number = 0;
  _r2: number = 0;

  constructor({
    originMarker,
    destMarker,
    map,
    clusters,
    options,
  }: IMarkersLinkProps) {
    super(options);

    this._circleMargin = 0;
    this._clusters = clusters;
    this._icon1 = originMarker.options.icon;
    this._icon2 = destMarker.options.icon;
    this._map = map;
    // const position1 = this._getMarkerPosition(originMarker);
    // const position2 = this._getMarkerPosition(destMarker);
    this._options = options;

    var visibleMarker1 =
      originMarker.__parent &&
      originMarker.__parent._group.getVisibleParent(originMarker)
        ? originMarker.__parent._group.getVisibleParent(originMarker)
        : originMarker
        ? originMarker
        : null;
    var visibleMarker2 =
      destMarker.__parent &&
      destMarker.__parent._group.getVisibleParent(destMarker)
        ? destMarker.__parent._group.getVisibleParent(destMarker)
        : destMarker
        ? destMarker
        : null;

    if (visibleMarker1 && visibleMarker2) {
      this._marker1latlng = visibleMarker1._latlng;
      this._marker2latlng = visibleMarker2._latlng;

      this._r1 = visibleMarker1._icon
        ? visibleMarker1._icon.clientHeight + this._circleMargin
        : visibleMarker1.options.icon.options.iconSize?.y || 0;
      this._r2 = visibleMarker2._icon
        ? visibleMarker2._icon.clientHeight + this._circleMargin
        : visibleMarker2.options.icon.options.iconSize?.y || 0;
    } else {
      this._marker1latlng = [
        originMarker.getLatLng().lat,
        originMarker.getLatLng().lng,
      ];
      this._marker2latlng = [
        destMarker.getLatLng().lat,
        destMarker.getLatLng().lng,
      ];
      this._r1 = this._icon1?.options.iconSize.y + this._circleMargin;
      this._r2 = this._icon2?.options.iconSize.y + this._circleMargin;
    }

    this._point1 = this._map.latLngToLayerPoint(this._marker1latlng);
    this._point2 = this._map.latLngToLayerPoint(this._marker2latlng);
  }

  _getMarkerPosition = (marker: any) => {
    let cluster: any = undefined;
    this._clusters?.forEach((c: any) => {
      const typeKeys = Object.keys(c.ContainedPOIs);
      typeKeys?.forEach((k: string) => {
        if (c.ContainedPOIs[k].includes(marker.Kid)) {
          cluster = c;
        }
      });
    });
    return cluster?._latlng || marker.getLatLng();
  };

  redraw = () => {
    return this;
  };
  _project = () => {
    return this;
  };

  _update = () => {
    this._updatePath();
    this._updateStyle();
    return this;
  };

  _updatePath = () => {
    let str = this._getPathString();
    if (!str) {
      str = 'M0 0';
    }
    this._path.setAttribute('d', str);
  };

  _getPathString = () => {
    this._point1 = this._map.latLngToLayerPoint(this._marker1latlng);
    this._point2 = this._map.latLngToLayerPoint(this._marker2latlng);
    const p1 = this._point1;
    const p2 = this._point2;
    const r1 = this._r1 / 2;
    const r2 = this._r2 / 2;

    if (L.Browser.svg) {
      var p2pVector = {
        x: p1.x - p2.x,
        y: p1.y - p2.y,
      };
      var vectorLength = Math.sqrt(
        Math.pow(p2pVector.x, 2) + Math.pow(p2pVector.y, 2)
      );
      var correctionVector = {
        x: p2pVector.x / vectorLength,
        y: p2pVector.y / vectorLength,
      };
      if (isNaN(correctionVector.x)) correctionVector.x = 0;
      if (isNaN(correctionVector.y)) correctionVector.y = 0;
      var linkstr = '';

      var p1correctionX = r1 * correctionVector.x;
      var p1correctionY = r1 * correctionVector.y;

      var p2correctionX = r2 * correctionVector.x;
      var p2correctionY = r2 * correctionVector.y;

      // line string between markers
      linkstr +=
        'M' +
        (p1.x - p1correctionX) +
        ',' +
        (p1.y - r1 - p1correctionY) +
        'L' +
        (p2.x + p2correctionX) +
        ',' +
        (p2.y - r2 + p2correctionY);

      // Circle around the marker 1
      linkstr +=
        'M' +
        p1.x +
        ',' +
        (p1.y - r1 * 2) +
        'A' +
        r1 +
        ',' +
        r1 +
        ',0,1,1,' +
        (p1.x - 0.1) +
        ',' +
        (p1.y - r1 * 2);
      // circle around the marker 2
      linkstr +=
        'M' +
        p2.x +
        ',' +
        (p2.y - r2 * 2) +
        'A' +
        r2 +
        ',' +
        r2 +
        ',0,1,1,' +
        (p2.x - 0.1) +
        ',' +
        (p2.y - r2 * 2);

      return linkstr;
    }
  };

  _updateStyle = () => {
    // Color
    if (this._options.color) {
      this._path.setAttribute('stroke', this._options.color);
    } else {
      this._path.setAttribute('stroke', 'none');
    }
    // Opacity
    if (this._options.opacity) {
      this._path.setAttribute('stroke-opacity', this._options.opacity);
    } else {
      this._path.removeAttribute('stroke-opacity');
    }
    // Weight
    if (this._options.weight) {
      this._path.setAttribute('stroke-width', this._options.weight);
    } else {
      this._path.removeAttribute('stroke-width');
    }
    // dashArray
    if (this._options.dashArray) {
      this._path.setAttribute('stroke-dasharray', this._options.dashArray);
    } else {
      this._path.removeAttribute('stroke-dasharray');
    }
    // lineCap
    if (this._options.lineCap) {
      this._path.setAttribute('stroke-linecap', this._options.lineCap);
    } else {
      this._path.removeAttribute('stroke-linecap');
    }
    // lineJoin
    if (this._options.lineJoin) {
      this._path.setAttribute('stroke-linejoin', this._options.lineJoin);
    } else {
      this._path.removeAttribute('stroke-linejoin');
    }
    // fill color
    if (this._options.fill) {
      this._path.setAttribute(
        'fill',
        this._options.fillColor || this._options.color
      );
    } else {
      this._path.setAttribute('fill', 'none');
    }
    // fill opacity
    if (this._options.fillOpacity) {
      this._path.setAttribute('fill-opacity', this._options.fillOpacity);
    } else {
      this._path.removeAttribute('fill-opacity');
    }
  };
}
