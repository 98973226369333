// React libs
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Layout
import AdminLayout from '../../../Components/Layout/AdminLayout/AdminLayout';
// Components
import LayersList from '../../../../Dimensions/Map/Components/Admin/LayersList/LayersList';
import LayerEdition from '../../../../Dimensions/Map/Components/Admin/LayerEdition/LayerEdition';
import Separator from '../../../../Core/Components/UiKit/Separator/Separator';
// Type
import * as Types from './LayerManagerScene.type';
import * as MapTypes from '../../../../Dimensions/Map/Data/Models/Map.type';

const LayerManagerScene: FC<Types.IProps> = () => {
  // Variables
  const { t } = useTranslation(['admin']);

  // State
  const [layerEditing, setlayerEditing]: [
    MapTypes.ILayer | number | undefined,
    Function
  ] = useState<MapTypes.ILayer | number | undefined>(undefined);
  const [needRefresh, setNeedRefresh] = useState(true);

  // Effects
  useEffect(() => {
    if (needRefresh) setNeedRefresh(false);
  }, [needRefresh]);

  // Handlers
  const onOpenEdit = (layer: MapTypes.ILayer | number) => {
    setlayerEditing(layer);
  };
  const onEditDone = (needRefresh: boolean = true) => {
    setlayerEditing(undefined);
    setNeedRefresh(needRefresh);
  };

  return (
    <AdminLayout
      headerConf={{
        title: {
          label: t('admin:title'),
          icon: 'picture-o',
          subLabel: t('admin:layers.title'),
        },
      }}
    >
      <div
        className='flex h-full items-center justify-center w-full'
        data-testid='layer-manager-page'
      >
        <div className='flex-1 h-full'>
          <LayersList
            onOpenEdit={onOpenEdit}
            needRefresh={needRefresh}
            layerEditing={
              typeof layerEditing === 'object'
                ? (layerEditing as MapTypes.ILayer).id
                : layerEditing
            }
          />
        </div>
        {layerEditing && (
          <div className='flex h-full min-w-1/2'>
            <Separator type='vertical' className='ml-4' />
            <LayerEdition
              layer={
                typeof layerEditing === 'object' ? layerEditing : undefined
              }
              onEditDone={onEditDone}
            />
          </div>
        )}
      </div>
    </AdminLayout>
  );
};

export default LayerManagerScene;
