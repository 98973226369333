// React libs
import React, { FC } from 'react';
import PropTypes from 'prop-types';
// Type
import * as Types from './FaIcon.type';

const FaIcon: FC<Types.IProps> = ({ type = 'fa', className, name, onClick }) => {
  return (
    <i
      className={`${type} fa-${name} ${className || ''}`}
      data-testid='fa-icon'
      onClick={onClick}
    />
  );
};

FaIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['fa', 'fas', 'fab']),
};

FaIcon.defaultProps = {};

export default FaIcon;
