// React libs
import React from 'react';
// Types
import * as MapTypes from '../Data/Models/Map.type';
// SVGs
import OtherPoiMarker from '../Resources/assets/svg/OtherPoiMarker';
import PoiProjectMarker from '../Resources/assets/svg/PoiProjectMarker';

export const translateStringCenter = (center: string): [number, number] => {
  const coords = center
    .replace('[', '')
    .replace(']', '')
    .split(',')
    .map((v: string) => parseFloat(v));
  return coords && coords.length === 2 ? [coords[0], coords[1]] : [1, 2];
};

export const creationMarkerId = 'poi_creation';
export const creationMarkerType = 'creation';
export const handleMarkerForPoiCreation = (
  pois: any[],
  position: [number, number],
  isProject: boolean
) => {
  return updatePoisListWithCreationMarker(
    pois,
    getMarkerForPoiCreation(position, isProject)
  );
};
export const getMarkerForPoiCreation = (
  position: [number, number],
  isProject: boolean
) => ({
  activity: '',
  allowedPersons: [],
  comment: '',
  createdAt: '',
  createdBy: '',
  currentPhaseType: '',
  email: '',
  fkaddress: '',
  fkfinancialforecast: '',
  fkimage: '',
  fkpoiType: creationMarkerType,
  fkterritory: '',
  geo: { type: 'Point', coordinates: position },
  hidden: false,
  id: creationMarkerId,
  isProject: isProject,
  keywords: '',
  lastModifyAt: '',
  lastModifyBy: '',
  naf: '',
  name: '',
  phone: '',
  url: '',
});

export const updatePoisListWithCreationMarker = (pois: any[], marker: any) => {
  const existingPoi = pois.find((p: any) => p.id === creationMarkerId);
  if (existingPoi) {
    existingPoi.geo.coordinates = marker.geo.coordinates;
  } else {
    pois.push(marker);
  }
  return pois;
};

interface IMarkerImageResponse {
  icon: any;
  size: [number, number];
  color: string;
}

export const getMarkerImage = (
  p: MapTypes.IPoi,
  poisLinks: MapTypes.IPoiLink[],
  phaseTypes: MapTypes.IPhaseType[],
  poiTypes: MapTypes.IPoiType[],
  linkTypes: MapTypes.ILinkType[]
): IMarkerImageResponse => {
  if (p.id === creationMarkerId) {
    return getCreationMarkerImage();
  } else if (p.isProject) {
    return getProjectMarkerImage(p, phaseTypes);
  } else {
    return getOtherMarkerImage(p, poisLinks, poiTypes, linkTypes);
  }
};

export const getCreationMarkerImage = (): IMarkerImageResponse => ({
  icon: <OtherPoiMarker color='#EF8354' icon='adjust' />,
  size: [33, 52],
  color: '#EF8354',
});

export const getProjectMarkerImage = (
  p: MapTypes.IPoi,
  phaseTypes: MapTypes.IPhaseType[]
): IMarkerImageResponse => {
  let color = undefined;
  let icon = undefined;
  let size: [number, number] = [0, 0];
  const phase = phaseTypes.find(
    (phase: MapTypes.IPhaseType) => phase.id === p.currentPhaseType
  );
  if (phase) {
    const style = phase.style.data;
    color = style.markerColor;
    icon = style.icon;
    size = [54, 50]; // style.iconSize; --> forced in current MIA
  }
  return {
    icon: <PoiProjectMarker color={color} icon={icon} />,
    size,
    color: color || '#dddddd',
  };
};

const getOtherMarkerImage = (
  p: MapTypes.IPoi,
  poisLinks: MapTypes.IPoiLink[],
  poiTypes: MapTypes.IPoiType[],
  linkTypes: MapTypes.ILinkType[]
): IMarkerImageResponse => {
  const link = poisLinks.find(
    (l: MapTypes.IPoiLink) => l.fkpoiFrom === p.id || l.fkpoiTo === p.id
  );
  if (link) {
    return getLinkMarkerImage(p, link, linkTypes, poiTypes);
  } else {
    return getResourceMarkerImage(p, poiTypes);
  }
};

const getLinkMarkerImage = (
  p: MapTypes.IPoi,
  link: MapTypes.IPoiLink,
  linkTypes: MapTypes.ILinkType[],
  poiTypes: MapTypes.IPoiType[]
): IMarkerImageResponse => {
  let color = undefined;
  let icon = undefined;
  const linkType = linkTypes.find(
    (type: MapTypes.ILinkType) => type.id === link.fklinkType
  );
  if (linkType) {
    const style = linkType.style.data;
    color = style.color;
  }
  const poiType = poiTypes.find(
    (type: MapTypes.IPoiType) => type.id === p.fkpoiType
  );
  if (poiType) {
    const style = poiType.style.data;
    icon = style.icon;
  }
  return {
    icon: <OtherPoiMarker color={color} icon={icon} />,
    size: [33, 52],
    color: color || '#dddddd',
  };
};

const getResourceMarkerImage = (
  p: MapTypes.IPoi,
  poiTypes: MapTypes.IPoiType[]
): IMarkerImageResponse => {
  let color = undefined;
  let icon = undefined;
  const type = poiTypes.find(
    (type: MapTypes.IPoiType) => type.id === p.fkpoiType
  );
  if (type) {
    const style = type.style.data;
    color = style.markerColor;
    icon = style.icon;
  }
  return {
    icon: <OtherPoiMarker color={color} icon={icon} />,
    size: [33, 52],
    color: color || '#dddddd',
  };
};


export const areFiltersSelected = (mapFilters: MapTypes.IMapFilters) => {
  let selection = false || mapFilters.links;
  if (selection) return selection;
  const links = mapFilters.markers.links;
  const linksKeys = Object.keys(links);
  for (let i = 0; i < linksKeys.length; i++) {
    if (selection) break;
    selection = selection || links[linksKeys[i]];
  }
  if (selection) return selection;
  const projects = mapFilters.markers.projects;
  const projectsKeys = Object.keys(projects);
  for (let j = 0; j < projectsKeys.length; j++) {
    if (selection) break;
    selection = selection || projects[projectsKeys[j]];
  }
  if (selection) return selection;
  const resources = mapFilters.markers.resources;
  const resourcesKeys = Object.keys(resources);
  for (let k = 0; k < resourcesKeys.length; k++) {
    if (selection) break;
    selection = selection || resources[resourcesKeys[k]];
  }
  if (selection) return selection
  return Object.keys(mapFilters.advanced).length !== 0;
};

export const formatMapFilters = (mapFilters: MapTypes.IMapFilters) => {
  // Links
  const linksFilters = mapFilters.markers.links;
  const linkType = Object.keys(linksFilters).reduce(
    (list: { id: string }[], key: string) => {
      if (linksFilters[key]) list.push({ id: key });
      return list;
    },
    []
  );

  // Pois
  const poisFilters = mapFilters.markers.resources;
  const poiType = Object.keys(poisFilters).reduce(
    (list: { id: string }[], key: string) => {
      if (poisFilters[key]) list.push({ id: key });
      return list;
    },
    []
  );

  // Phases
  const phasesFilters = mapFilters.markers.projects;
  const phaseType = Object.keys(phasesFilters).reduce(
    (list: { id: string }[], key: string) => {
      if (phasesFilters[key]) list.push({ id: key });
      return list;
    },
    []
  );
  const filters = {
    category: {
      linkType,
      poiType,
      phaseType,
      showMapLinks: mapFilters.links,
    },
    filters: mapFilters.advanced,
  };

  return filters;
}

export const parseMapFilters = (filters: any): Partial<MapTypes.IMapFilters> => {
  const links: { [id: string]: boolean } = {}
  filters.category.linkType?.forEach(({ id }: { id: string }) => {
    links[id] = true
  })

  const resources: { [id: string]: boolean } = {}
  filters.category.poiType?.forEach(({ id }: { id: string }) => {
    resources[id] = true
  })

  const projects: { [id: string]: boolean } = {}
  filters.category.phaseType?.forEach(({ id }: { id: string }) => {
    projects[id] = true
  })

  return {
    markers: {
      links,
      projects,
      resources
    },
    links: filters.category.showMapLinks,
    advanced: filters.filters
  }
}
