// React libs
import React, { FC, useCallback, useState, useMemo, useContext } from 'react';
import moment from 'moment'
import { ClickAwayListener, Tooltip, Paper, IconButton, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import { Field, FieldArray } from 'formik'
import { useTranslation } from 'react-i18next';
// Components
import Autocomplete from '../../../UiKit/Form/Autocomplete/Autocomplete'
import CheckboxGroup from '../../../UiKit/Form/CheckboxGroup/CheckboxGroup'
import DatePicker from '../../../UiKit/Form/DatePicker/DatePicker'
import FaIcon from '../../../UiKit/Icon/FaIcon/FaIcon';
import RadioGroup from '../../../UiKit/Form/RadioGroup/RadioGroup'
import Select from '../../../UiKit/Form/Select/Select'
import TextField from '../../../UiKit/Form/TextField/TextField'
import ThreeColorsRadio, { ColorRadioByValue } from '../../../UiKit/Form/ThreeColorsRadio/ThreeColorsRadio'
// Contexts
import AdvancedFiltersContext, { IAdvancedFiltersContext } from '../../../../Data/Contexts/AdvancedFiltersContext'
// Types
import * as CoreTypes from '../../../../Data/Models/Core.type'
import * as Types from './AdvancedFiltersTable.type'
// Utils
import { IOption } from '../../../../Utils/FormUtils'
import { defaultAdvancedFiltersNames, advancedFiltersOperators } from '../../../../Utils/Filters'

const FieldWrapper = ({ children, label = '', disabled = false }: Types.IFieldWrapper) => {
  // State
  const [isFieldDisplayed, setIsFieldDisplayed] = useState<boolean>(false)
  // Actions
  const displayField = useCallback(() => setIsFieldDisplayed(true), [])
  const hideField = useCallback(() => setIsFieldDisplayed(false), [])

  return isFieldDisplayed
    ? <ClickAwayListener mouseEvent="onMouseDown" onClickAway={hideField}><div>{children}</div></ClickAwayListener>
    : <div className='w-full bg-main-light text-select text-center text-xs'>
      {disabled
        ? <label>{label}</label>
        : <label className='cursor-pointer' onClick={displayField}>{label}</label>
      }
    </div>
}

const FIELD_CLASSES = {
  root: 'p-0 bg-main-light'
}

const getLabel = (value: any, options: IOption[], defaultLabel: string) => {
  const getOptionLabel = (value: any) => options.find(option => option.value === value)?.label ?? value
  return value === '' || value.length === 0
    ? defaultLabel
    : Array.isArray(value)
      ? value.map((singleValue: any) => getOptionLabel(singleValue)).join(', ')
      : getOptionLabel(value)
}

const Name = ({ basePath, filter, filters, onSelectValue, type }: Types.IName) => {
  // Contexts
  const { data }: IAdvancedFiltersContext = useContext(AdvancedFiltersContext)
  // Variables
  const { t } = useTranslation('map')
  const options = [
    ...Object.values(defaultAdvancedFiltersNames).map(({ label, value }) => ({
      label: t(label),
      value
    })),
    ...Object.values(data[type].valueDefs).map(valueDef => ({
      label: valueDef.label,
      value: valueDef.id
    }))
  ]
  const namesPredicate = useCallback(option => !filters.some(({ name }) => name === option.value), [filters])
  const label = useMemo(() => getLabel(filter.name, options, t('map:sidebar.filters.advancedFilters.name')), [filter.name, t, options])
  return <TableCell classes={FIELD_CLASSES}>
    <FieldWrapper label={label}>
      <Field
        name={`${basePath}.name`}
        component={Select}
        onSelectValue={onSelectValue}
        options={options}
        predicate={namesPredicate}
      />
    </FieldWrapper>
  </TableCell >
}

const Operator = ({ basePath, filter, type }: Types.IOperator) => {
  // Contexts
  const { data }: IAdvancedFiltersContext = useContext(AdvancedFiltersContext)
  // Variables
  const { t } = useTranslation('map')
  const options = useMemo(() => {
    const widgetId = defaultAdvancedFiltersNames[filter.name]?.widget.id ?? data[type].valueDefs[filter.name]?.widget.id
    return widgetId !== undefined
      ? Object.values(advancedFiltersOperators)
        .filter(({ widgets }) => widgets.includes(widgetId))
        .map(({ label, value }) => ({
          label: t(label),
          value
        }))
      : []
  }, [filter.name, data, type, t])
  const label = useMemo(() => getLabel(filter.operator, options, t('map:sidebar.filters.advancedFilters.operator')), [filter.operator, t, options])
  return <TableCell classes={FIELD_CLASSES}>
    <FieldWrapper label={label} disabled={filter.name === ''}>
      <Field
        component={Select}
        name={`${basePath}.operator`}
        options={options}
      />
    </FieldWrapper>
  </TableCell>
}

const Value = ({ basePath, filter, type }: Types.IValue) => {
  // Contexts
  const { data }: IAdvancedFiltersContext = useContext(AdvancedFiltersContext)
  // Variables
  const { t } = useTranslation('map')
  const valueDef = data[type].valueDefs[filter.name]
  const defaultFilterName = defaultAdvancedFiltersNames[filter.name]
  const widgetId = defaultFilterName?.widget.id ?? valueDef?.widget.id
  const options = useMemo(() => {
    if (widgetId === 'BOOLEAN') {
      return [
        {
          label: t('map:sidebar.filters.advancedFilters.yes'),
          value: true
        },
        {
          label: t('map:sidebar.filters.advancedFilters.no'),
          value: false
        }
      ]
    }

    if (!['VALUESLIST', 'LIST', 'RADIOS', 'CHECKBOXES'].includes(widgetId)) {
      return []
    }

    if (defaultFilterName !== undefined) {
      return defaultFilterName.getData?.(data, type).map((item: any) => ({
        label: item.label,
        value: item.id
      }))
    }

    return valueDef.valueChoices.map(({ label, value }: CoreTypes.IValueChoice) => ({
      label,
      value
    }))
  }, [widgetId, defaultFilterName, valueDef, data, type, t])
  const label = useMemo(() => getLabel(filter.value, options, t('map:sidebar.filters.advancedFilters.value')), [filter.value, t, options])
  const commonFieldWrapperProps = {
    label,
    disabled: filter.operator === ''
  }

  switch (widgetId) {
    case 'LIST': case 'BOOLEAN':
      return <TableCell classes={FIELD_CLASSES}>
        <FieldWrapper {...commonFieldWrapperProps}>
          <Field
            name={`${basePath}.value`}
            component={Autocomplete}
            options={options}
            color='secondary'
          />
        </FieldWrapper>
      </TableCell>
    case 'VALUESLIST':
      return <TableCell classes={FIELD_CLASSES}>
        <FieldWrapper {...commonFieldWrapperProps}>
          <Field
            name={`${basePath}.value`}
            component={Autocomplete}
            options={options}
            color='secondary'
            multiple
          />
        </FieldWrapper>
      </TableCell>
    case 'NUMBER':
      return <TableCell classes={FIELD_CLASSES}>
        <FieldWrapper {...commonFieldWrapperProps}>
          <Field
            name={`${basePath}.value`}
            component={TextField}
            color='secondary'
            type='number'
            transform='integer'
          />
        </FieldWrapper>
      </TableCell>
    case 'FLOAT':
      return <TableCell classes={FIELD_CLASSES}>
        <FieldWrapper {...commonFieldWrapperProps}>
          <Field
            name={`${basePath}.value`}
            component={TextField}
            color='secondary'
            type='number'
            transform='float'
          />
        </FieldWrapper>
      </TableCell>
    case 'RADIOS':
      return <TableCell classes={FIELD_CLASSES}>
        <FieldWrapper {...commonFieldWrapperProps}>
          <Field
            name={`${basePath}.value`}
            component={RadioGroup}
            options={options}
            color='secondary'
          />
        </FieldWrapper>
      </TableCell>
    case 'CHECKBOXES':
      return <TableCell classes={FIELD_CLASSES}>
        <FieldWrapper {...commonFieldWrapperProps}>
          <Field
            name={`${basePath}.value`}
            component={CheckboxGroup}
            options={options}
            color='primary'
          />
        </FieldWrapper>
      </TableCell>
    case 'DATE':
      commonFieldWrapperProps.label = filter.value !== '' ? moment(filter.value).format('DD/MM/YYYY') : label
      return <TableCell classes={FIELD_CLASSES}>
        <FieldWrapper {...commonFieldWrapperProps}>
          <Field
            name={`${basePath}.value`}
            component={DatePicker}
            transform={(date: any) => (date !== '' && date != null) ? date.millisecond(0).second(0).minute(0).hour(0).toISOString() : ''}
            color='secondary'
          />
        </FieldWrapper>
      </TableCell>
    case 'THREECOLORSSCALES':
      if (filter.value !== '') {
        const value: '0' | '1' | '2' = filter.value
        const Component = ColorRadioByValue[value]
        commonFieldWrapperProps.label = <Component selectedValue={value} />
      }
      return <TableCell classes={FIELD_CLASSES}>
        <FieldWrapper {...commonFieldWrapperProps}>
          <Field
            name={`${basePath}.value`}
            component={ThreeColorsRadio}
          />
        </FieldWrapper>
      </TableCell>
    default: return <TableCell classes={FIELD_CLASSES}>
      <FieldWrapper {...commonFieldWrapperProps}>
        <Field
          name={`${basePath}.value`}
          component={TextField}
          color='secondary'
        />
      </FieldWrapper>
    </TableCell>
  }
}

const AdvancedFiltersTable: FC<Types.IProps> = ({ type, values, className }) => {
  // Variables
  const { t } = useTranslation('map')
  const classes = {
    root: 'bg-main-light text-selection text-center'
  }
  const title = t(`map:sidebar.filters.advancedFilters.${type === 'project' ? 'projectFilters' : 'resourceFilters'}`).toUpperCase()
  const defaultValue = {
    name: '',
    operator: '',
    value: ''
  }

  // Handlers
  const resetFilters = useCallback(
    remove => values.reduceRight((accumulator: any[], value: any, index: number) => remove(index), []),
    [values]
  )

  return <FieldArray name={type}>
    {({ remove, push, replace }) => <div data-testid='advanced-filters-table' className={className}>
      <TableContainer component={Paper}>
        <Table size='small' aria-label={`${type} table`}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} classes={classes}>
                {title}
                <Tooltip title={t('map:sidebar.filters.advancedFilters.cleanFilters') ?? ''}>
                  <span className='text-xl cursor-pointer float-right' onClick={() => resetFilters(remove)}>
                    <FaIcon name='times' />
                  </span>
                </Tooltip>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((value: any, index: number) => {
              const basePath = `${type}.${index}`
              return <TableRow key={index}>
                <Name basePath={basePath} filter={value} type={type} filters={values} onSelectValue={value => replace(index, {
                  ...defaultValue,
                  name: value
                })} />
                <Operator basePath={basePath} filter={value} type={type} />
                <Value basePath={basePath} filter={value} type={type} />
                <TableCell classes={FIELD_CLASSES} className='text-right'>
                  <IconButton onClick={() => remove(index)}>
                    <FaIcon
                      name='trash'
                      className='text-xl'
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className='flex justify-center mt-2'>
        <FaIcon type='fas' name='plus-circle' className='text-green-500 text-3xl cursor-pointer' onClick={() => push(defaultValue)} />
      </div>
    </div>}
  </FieldArray>
}

export default AdvancedFiltersTable