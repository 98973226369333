// React libs
import React, { FC } from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
// Types
import * as Types from './PdfHeader.type';
// Common
import Common from '../../../Resources/Common';

const STYLES = {
  header: Common.PdfStyles.centerRowItems,
  logo: {
    wrap: [
      Common.PdfStyles.centerRowItems,
      {
        position: 'absolute',
        width: '10%',
      }
    ],
    image: {
      height: 40,
      width: 40
    },
    text: {
      fontSize: 20,
      marginLeft: 4,
    }
  },
  title: {
    view: {
      maxWidth: '50vw',
      maxHeight: '17vh',
      marginHorizontal: 'auto',
    },
    text: {
      fontSize: 16,
      textAlign: 'justify'
    }
  }
}

const PdfHeader: FC<Types.IProps> = ({ title = '' }) =>
  <View style={STYLES.header}>
    <View style={STYLES.logo.wrap}>
      <Image
        src={`${process.env.PUBLIC_URL}/logo192.png`}
        style={STYLES.logo.image}
      />
      <Text style={STYLES.logo.text}>mia</Text>
    </View>
    <View
      style={STYLES.title.view}
    >
      <Text style={STYLES.title.text}>{title}</Text>
    </View>
  </View>

export default PdfHeader