// React libs
import React, { FC } from 'react';
import PropTypes from 'prop-types';
// Components
import TransparentPanel from '../../../../../../Core/Components/UiKit/Panel/TransparentPanel/TransparentPanel';
// Type
import * as Types from './MapHeaderPanel.type';
import CreateProjectPanel from './CreateProjectPanel/CreateProjectPanel';
import CreateResourcePanel from './CreateResourcePanel/CreateResourcePanel';

export const HeaderPanelContentKeys = {
  project: 'project',
  resource: 'resource',
};

const MapHeaderPanel: FC<Types.IProps> = ({ contentKey, onCreationDone }) => {
  // Variables
  const whichRender = {
    [HeaderPanelContentKeys.project]: (
      <CreateProjectPanel onCreationDone={onCreationDone} />
    ),
    [HeaderPanelContentKeys.resource]: (
      <CreateResourcePanel onCreationDone={onCreationDone} />
    ),
  };

  return (
    <div
      className='absolute bg-transparent flex flex-col h-20 left-0 overflow-hidden top-0 w-full z-1600'
      data-testid='map-header-panel'
    >
      <TransparentPanel />
      <div className='flex h-full items-center justify-between p-2 text-white w-full z-10'>
        {whichRender[contentKey]}
      </div>
    </div>
  );
};

MapHeaderPanel.propTypes = {
  contentKey: PropTypes.string.isRequired,
};

export default MapHeaderPanel;
